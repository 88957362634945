import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';

import { AppConfigService } from '../../../../services/app-config.service';
import { AuthService } from '../../../../services/auth.service';
import { StateService } from '../../../../services/state.service';
import { LoginService } from 'src/app/services/login.service';

import { CompanyCodes } from 'src/app/models/company';

import * as _ from 'lodash';

/**
 * Dummy test login
 */
@Component({
    selector: 'app-callback',
    templateUrl: './callback.component.html'
})
export class CallbackComponent implements OnInit {
    public loginParams: any;
    public loginError: any;
    public loginErrorDescription: any;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private appConfigService: AppConfigService,
        private authService: AuthService,
        public stateService: StateService,
        private loginService: LoginService
    ) {
    }

    ngOnInit() {
        setTimeout(async () => {
            this.stateService.state.hasMenu = false;
        });

        this.route.queryParams.subscribe(params => {
            if (params.code) {
                this.loginParams = {
                    code: params.code,
                    state: params.state,
                    redirectUri: this.appConfigService.config.frontendUrl + '/callback'
                };
                this.login();
            } else if (params.error) {
                // No required params
                console.log('Callback error. Missing required params');

                this.loginError = _.get(params, 'error');
                this.loginErrorDescription = _.get(params, 'error_description');
            } else {
                this.router.navigate([], { replaceUrl: true });
            }
        });
    }

    login() {
        console.log('login()', this.loginParams);
        // Call backend /login endpoint, returns token on success.
        this.loginService.login(this.loginParams).subscribe((response: any) => {
            console.log('login response', response);
            if (response) {
                this.authService.handleLogin(response);
            } else {
                this.loginError = 'Login error';
            }
        }, (error) => {
            this.loginError = _.get(error, 'error.errorMessage') || 'Login error';
            console.log('ERROR during login', this.loginError);
        });
    }

    logout(): void {
        this.authService.logout();
    }
}
