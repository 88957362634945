import { Component } from '@angular/core';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { StateService } from './services/state.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    title = 'iam-mykemppi';
    appInfo = {
        icon: 's',
        title: 'My Kemppi'
    };

    constructor(
        private router: Router,
        private translate: TranslateService,
        public stateService: StateService
    ) {

        // Clear loading state after navigation end
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.stateService.state.loading = false;
            }
        });

        // this language will be used as a fallback when a translation isn't found in the current language
        this.translate.setDefaultLang('en');
    }

    back() {
        // if (this.router.url.split('/')[1] === 'maintain-software') {
        //     this.router.navigate(['maintain-software']);
        // }
    }

}
