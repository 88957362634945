import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { startWith, catchError, switchMap } from 'rxjs/operators';
import { Observable, interval, of } from 'rxjs';
import { IApplicationIds } from '../models/applications';

export interface MaintenanceMessage {
    /** The maintenance message */
    message: string;
    /** List of application ids which are disabled */
    disabledApps?: IApplicationIds[]
}

@Injectable()
export class MaintenanceService {
    /** Interval on which the application polls for maintenance info. Currently once per 30 min */
    private POLLING_INTERVAL: number = 1000 * 60 * 30;
    constructor(private http: HttpClient) {}
    /**
     * Get maintenance message from the json file in application root for the current language (or just english always?)
     */
    getMaintenanceMessage(): Observable<MaintenanceMessage> {
        // interval on which we request for the maintenance message
        return interval(this.POLLING_INTERVAL).pipe(
            // first time calling this function, skip the interval
            startWith(0),
            // Mutate the interval result into our http request result
            switchMap(() => this.http.get<MaintenanceMessage>(`maintenance-message.json?${Date.now()}`)),
            // if an error occurs (90% of the time its 404 due to the file not existing at root), just return an empty string as message
            catchError(val => of({ message: '' }))
        );
    }

}


