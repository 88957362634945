import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { TranslateService } from '@ngx-translate/core';

import { AppConfigService } from './app-config.service';
import { LocaleService } from './locale.service';

import { environment } from '../../environments/environment';

import { UserProfile } from '../models/auth-user';
import { StateService } from './state.service';

@Injectable()
export class AuthService implements CanActivate {
    BACKENDURL: string;

    public userProfilePromise: any;

    private _authenticated;
    private _userProfile: UserProfile;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private http: HttpClient,
        private appConfigService: AppConfigService,
        private localeService: LocaleService,
        private stateService: StateService
    ) {
        console.log('AuthService::constructor()')
        this.BACKENDURL = this.appConfigService.config.iamBackendUrl;
        this.loginWithToken(this.accessToken);
    }

    // Add this service to router as the canActivate gate.
    // Router will call this function for each route thus protected.
    async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log('AuthService::canActivate()', route.routeConfig.path);
        await this.userProfilePromise; // Wait for get profile request to be finished
        if (this.authenticated()) {
            console.log('AuthService::canActivate() authenticated', route.routeConfig.path);
            // Show toolbar on top?
            // if (this.userProfile.permissions.modules['management']) {
            //     this.stateService.state.hideToolbar = false;
            // } else {
            //     this.stateService.state.hideToolbar = true;
            // }

            if (route.routeConfig.path === 'landing' || route.routeConfig.path === 'callback') {
                return true;
            }
            if (route.routeConfig.path === '' || route.routeConfig.path === 'portal' || route.routeConfig.path === 'account') {
                if (this.userProfile.permissions.modules['portal']) {
                    return true;
                }
            }
            if (route.routeConfig.path === 'management' || route.routeConfig.path === 'management/:id') {
                if (this.userProfile.permissions.modules['management']) {
                    return true;
                }
            }
            // // console.log('canActivate, route', route);
            // // console.log('canActivate, state', state);
            return false;
        } else {
            this.login();
            return false;
        }
    }

    get accessToken(): string {
        try {
            return localStorage.getItem('access_token');
        } catch (e) {
            return null;
        }
    }
    set accessToken(accessToken: string) {
        if (accessToken) {
            localStorage.setItem('access_token', accessToken);
        }
    }

    get userProfile() {
        return this._userProfile;
    }

    async loginWithToken(token) {
        console.log('AuthService::loginWithToken()');
        if (token) {
            // check that the token exists and it is not expired
            const jwtHelperService = new JwtHelperService();
            const payload = jwtHelperService.decodeToken(token);
            let userProfile = payload.user;
            console.log('AuthService::loginWithToken() userProfile', userProfile);

            // Check is token expired
            const unixEpochTimeNow = new Date().getTime() / 1000;
            if (payload && payload.exp && unixEpochTimeNow > payload.exp) {
                userProfile = null;
                console.log('AuthService::loginWithToken() token is expired');
            }

            if (userProfile) {
                // Get user profile (backend IAM appended user with permissions)
                this.userProfilePromise = this.http.get(`${this.BACKENDURL}/auth/profile`, {
                    headers: new HttpHeaders({ Authorization: `Bearer ${token}` })
                }).toPromise().then((authProfileData: any) => {
                    console.log('AuthService::loginWithToken() authProfileData', authProfileData);

                    userProfile.permissions = authProfileData.permissions;

                    // Store token into local storage
                    this.accessToken = token;
                    this._userProfile = userProfile;
                    this._authenticated = true;
                    // Change language
                    if (this._userProfile.language) {
                        // await this.localeService.language = this._userProfile.language;
                        this.localeService.language = this._userProfile.language;
                    }
                }).catch((error) => {
                    console.log('AuthService::loginWithToken() authProfileData error');
                    this.accessToken = null;
                    this._userProfile = null;
                    this._authenticated = false;
                });
            }
        } else {
            this.accessToken = null;
            this._userProfile = null;
            this._authenticated = false;
        }
    }

    async handleLogin(token) {
        if (token) {
            // Store accessToken
            this.accessToken = token;
            // Login with it (same as refreshing page)
            await this.loginWithToken(token);
            // Redirect to main
            // window.location.replace('/');
            this.router.navigate(['portal']);
        }
    }

    authenticated() {
        return (this._authenticated);
    }

    login() {

        // if (this.appConfigService.config.environment === 'local') {
        //     window.location.replace('/testlogin');
        // }
        const auth0Url = `https://${this.appConfigService.config.oauth2Domain}`;
        const clientId = this.appConfigService.config.oauth2ClientId;
        const redirectUri = this.appConfigService.config.frontendUrl + '/callback';

        window.location.replace(`${auth0Url}/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=openid%20profile%20email`);

        // Response should be something like this:
        // http://localhost:4200/callback
        //   ?code=aPrxdbJqxvRUUKm15bsjfGBwM4xDryLQibptQECAfynZRYzCQHln9wWLUZhGEi9xoRP38D7btA%3D%3D
    }

    logout() {
        this._authenticated = false;
        this._userProfile = null;
        localStorage.clear();

        // if (this.appConfigService.config.environment === 'local') {
        //     window.location.replace('/testlogin');
        // } else {
        // Logout user from Auth0 when logging out of IAM
        const clientId = this.appConfigService.config.oauth2ClientId;
        const url = `https://${this.appConfigService.config.oauth2Domain}/v2/logout?client_id=${clientId}`;
        window.location.replace(url);
        // }
        // Redirect to main
        // const kemppiIamPortal = this.appConfigService.config.kemppiIamUrl;
        // window.location.replace(kemppiIamPortal);
    }

    isAllowedTo(action, company = null, partnerId = null) {
        const user = this.userProfile;
        company = company || this.userProfile.company;
        partnerId = partnerId || this.userProfile.partnerId;
        let scopeAllowed = true;
        let actionAllowed = true;

        // System admin always allowed
        if (user.role === 'sys_admin') {
            return true;
        }

        // Check user scope
        if (user.permissions.scope === 'global') {
            // All company and partnerId's allowed
        } else if (user.permissions.scope === 'company') {
            if (user.company !== company) {
                scopeAllowed = false;
            }
        } else if (user.permissions.scope === 'partner') {
            if (user.company !== company || user.partnerId !== partnerId) {
                scopeAllowed = false;
            }
        } else {
            // Just in case, all other scopes are not allowed
            scopeAllowed = false;
        }

        // Check user action
        if (user.permissions.actions[action]) {
            // Action found from user permissions
        } else {
            actionAllowed = false;
        }

        return (scopeAllowed && actionAllowed);
    }

}
