<div mat-dialog-title>
    <h3>{{ data.type | translate }}</h3>
</div>
<div mat-dialog-content>
    <mat-form-field>
        <mat-label>{{ data.label | translate }}</mat-label>
        <input matInput [(ngModel)]="data.value">
    </mat-form-field>
</div>
<div mat-dialog-actions fxLayoutAlign="center center" fxLayoutGap="10px">
    <button mat-button mat-raised-button mat-dialog-close="" color="primary"><span translate>common.cancel</span></button>
    <button mat-button mat-raised-button mat-dialog-close="ok" color="accent"><span translate>common.ok</span></button>
</div>