import * as _ from 'lodash';

import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AppConfigService } from '../../../../services/app-config.service';
import { AuthService } from '../../../../services/auth.service';
import { StateService } from '../../../../services/state.service';

@Component({
    selector: 'app-landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
    public loading: boolean;

    public authUserScope: string;
    public allowedModules: any;

    public activationToken?: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private appConfigService: AppConfigService,
        private authService: AuthService,
        public stateService: StateService
    ) {
        this.loading = true;
    }

    ngOnInit() {
        this.activationToken = null;
        setTimeout(async () => {
            this.stateService.state.file = '';
            this.stateService.state.hasMenu = true;

            // Set menu allowedModules by user.permissions.mudules
            if (this.authService.userProfile) {
                this.allowedModules = this.authService.userProfile.permissions.modules;
                this.authUserScope = this.authService.userProfile.permissions.scope;
            }

            this.loading = false;
        });
    }

}
