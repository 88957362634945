import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';

// Services
import { AuthService } from 'src/app/services/auth.service';
import { ManagementService, UserSearchResponse } from 'src/app/services/management.service';
import { MachineryService } from 'src/app/services/machinery.service';
import { StateService } from 'src/app/services/state.service';

// Models
import { Company, Companies } from 'src/app/models/company';
import { Permissions, UserProfile } from 'src/app/models/auth-user';
import { IAMUser, REGISTRATION_APPROVAL } from 'src/app/models/IAMUser';

import { Applications, IApplications, ApplicationIds } from 'src/app/models/applications';

export interface SearchParams {
    company?: string;
    partnerId?: string;
    size?: number;
    approval?: REGISTRATION_APPROVAL;
    sort?: string;
    direction?: string
}

@Component({
    selector: 'app-users-listing-page',
    templateUrl: './users-listing-page.component.html',
    styleUrls: ['./users-listing-page.component.scss']
})
export class UsersListingPageComponent implements OnInit {
    public objectKeys = Object.keys;
    /**
     * List of managed users
     */
    users: Array<IAMUser>;

    /**
     * Search parameters, passed to search
     */
    searchParams: SearchParams;
    searchName: string;
    searchEmail: string;
    searchCompany: string;
    searchPartnerId: string;
    searchSize: number;
    searchFrom: number;
    searchApprovalStatus: REGISTRATION_APPROVAL | '';
    searchInternal: boolean;
    searchInitial: boolean;
    searchApp: string;
    searchRole: string;

    // Customer/partner text search
    searchPartner: string;
    searchCustomerTimer: any;
    customersList = [];
    customersFormControl = new FormControl();

    /**
     * Users count
     */
    resultHits: number;

    /**
     * Users total count
     */
    totalHits: number;

    loadingUsers: boolean;

    selectedItems: object;

    expandedItems: { [itemId: string]: boolean } = {};
    expandAll: boolean = false;

    /**
     * List of companies
     */
    companies: Array<Company> = [];

    /**
     * Applications ids Array<string>
     */
    applications: IApplications;

    applicationsFormatted: { roles: { [roleId: string]: string } };

    /**
     * Applications ids Array<string>
     */
    applicationIds: Array<string>;

    /**
     * Registration approval states
     */
    regApprovalStates = REGISTRATION_APPROVAL;

    /**
     * Profile of authenticated user
     */
    authUser: UserProfile;

    /**
     * User permissions, same as authService.userProfile.permissions
     */
    permissions: Permissions = {
        scope: '',
        modules: {},
        actions: {}
    };

    /**
     * Sorting parameters
     */
    sortParams = {
        key: 'data.name.keyword',
        direction: 'asc'
    };

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private managementService: ManagementService,
        private machineryService: MachineryService,
        private stateService: StateService
    ) {
        this.companies = Companies;
        this.applicationIds = ApplicationIds;
        this.applications = Applications;
        this.applicationsFormatted = this.formatApplications(Applications);
        this.loadingUsers = false;
        this.selectedItems = {};
    }
    ngOnInit() {
        setTimeout(() => {
            this.stateService.state.file = '';
            this.authUser = this.authService.userProfile;
            this.permissions = this.authUser.permissions;

            this.resetSearchParams();
            // Filters are stored to browser local storage and reloaded here.
            this.searchParams = this.searchParamsInStorage || [];
            console.log('init', this.searchParams, this.searchCompany);
            this.reloadFilters();

            this.search();
        });
    }

    reloadFilters() {
        console.log('reloadFilters', this.searchParams);
        if (this.permissions.scope === 'global') {
            this.searchCompany = this.getSearchParamByKey('company');
        }
        this.searchPartnerId = this.getSearchParamByKey('partnerId');
        this.searchName = this.getSearchParamByKey('name');
        this.searchEmail = this.getSearchParamByKey('email');
        this.searchApprovalStatus = this.getSearchParamByKey('approval');
        this.searchApp = this.getSearchParamByKey('app');
        this.searchRole = this.getSearchParamByKey('role');
    }

    filterChanged() {
        // When filters change, start to search from the beginning of the index again
        this.searchFrom = 0;
        this.users = [];
        this.totalHits = 0;
        this.resultHits = 0;
        this.search();
    }

    /** Track users in list by ID. Reduces excess change propagation done by angular */
    trackById(index: number, user: IAMUser) {
        return user.id;
    }

    getSearchParamByKey(key) {
        const searchParamVal = _.get(this.searchParams, key);
        return searchParamVal ? searchParamVal.replace(/[*]/g, '') : '';
    }

    sort(sortKey) {
        console.log('sort', sortKey);
        const currentSortObj = this.sortParams || null;
        let sortDirection = 'asc';
        if (currentSortObj && currentSortObj.key && currentSortObj.key === sortKey) {
            sortDirection = this.sortParams.direction === 'desc' ? 'asc' : 'desc';
        }
        this.sortParams = {
            key: sortKey,
            direction: sortDirection
        };
        // Clear results
        this.totalHits = 0;
        this.search();
    }

    search() {
        if (!this.loadingUsers) {
            this.checkSearchParams();
            this.searchUsers();
        }
    }

    loadMore() {
        this.searchFrom += this.searchSize;
        this.searchSize = 100;
        this.search();
    }

    searchUsers() {
        this.loadingUsers = true;
        this.managementService.searchUsers(this.searchParams).subscribe((response: UserSearchResponse) => {
            // Search total result count changed and therefore the filters changed, override users 
            if (this.totalHits !== response.totalCount || response.totalCount === 0) {
                this.users = response.items || [];
            } else {
                // We just loaded more users, merge the results
                this.users = _.uniqBy((this.users || []).concat(response.items || []), user => user.id);
            }
            // Update result/total coutns
            this.totalHits = response.totalCount;
            this.resultHits = this.users.length;
        }, (error) => {
            console.error('Error, searchUsers', error);
        }, () => {
            this.loadingUsers = false;
        });
    }

    checkSearchParams() {
        this.searchParams = {};
        if (this.searchName !== '') {
            _.set(this.searchParams, 'name', this.searchName.toLowerCase());
        }
        if (this.searchEmail !== '') {
            _.set(this.searchParams, 'email', this.searchEmail.toLowerCase());
        }
        if (this.searchCompany !== '') {
            _.set(this.searchParams, 'company', this.searchCompany);
        }
        if (this.searchPartnerId !== '') {
            _.set(this.searchParams, 'partnerId', this.searchPartnerId);
        }
        if (this.searchApp) {
            _.set(this.searchParams, 'app', this.searchApp);
        }
        if (this.searchRole) {
            _.set(this.searchParams, 'role', this.searchRole);
        }
        if (this.searchSize) {
            _.set(this.searchParams, 'size', this.searchSize);
        }
        if (this.searchFrom != null) {
            _.set(this.searchParams, 'from', this.searchFrom);
        }
        if (this.searchApprovalStatus) {
            _.set(this.searchParams, 'approval', this.searchApprovalStatus);
            _.set(this.searchParams, 'approval', this.searchApprovalStatus);
        }
        if (this.searchInternal) {
            _.set(this.searchParams, 'internal', true);
        }
        if (this.searchInitial) {
            _.set(this.searchParams, 'initial', true);
        }
        if (this.sortParams) {
            _.set(this.searchParams, 'sort', `${this.sortParams.key}:${this.sortParams.direction}`);
        }
        this.searchParamsInStorage = this.searchParams;
    }

    resetSearchParams() {
        this.searchName = '';
        this.searchEmail = '';
        this.searchPartner = '';
        this.searchCompany = '';
        this.searchPartnerId = '';
        this.searchApp = '';
        this.searchRole = '';
        this.searchApprovalStatus = '';
        this.searchInternal = false;
        this.searchInitial = false;
        this.searchSize = 20;
        this.searchFrom = 0;
        this.sortParams = {
            key: 'data.name.keyword',
            direction: 'asc'
        };
    }

    get searchParamsInStorage(): object {
        try {
            const paramsString = localStorage.getItem('users_search_params');
            console.log('searchParamsInStorage', paramsString);
            return JSON.parse(paramsString);
        } catch (e) {
            return [];
        }
    }

    set searchParamsInStorage(searchParams: object) {
        console.log('searchParamsInStorage', searchParams);
        localStorage.setItem('users_search_params', JSON.stringify(searchParams));
    }

    searchCustomers() {
        if (this.searchPartner && this.searchPartner.length > 3) {
            clearTimeout(this.searchCustomerTimer);
            this.searchCustomerTimer = setTimeout(() => {
                const params = [
                    { key: 'searchName', value: '*' + this.searchPartner.toUpperCase() + '*' }
                ];
                this.machineryService.searchCustomers(params).subscribe((results) => {
                    this.customersList = results as Array<any>;
                }, (error) => {
                    console.log('Error, searchCustomers', error);
                });
            }, 1000); // Time in ms before calling the backend search
        }
    }

    changeCustomer(customer) {
        if (customer && customer.value && customer.value.name && customer.value.custId && customer.value.company) {
            console.log('changeCustomer', customer.value);
            this.searchPartner = customer.value.name;
            this.searchPartnerId = customer.value.custId;
            this.searchCompany = customer.value.company;
            this.search();
        }
    }

    createNewUser() {
        this.router.navigate(['management', 'new']);
    }

    /** Format applications to a more minimal format for the listing */
    formatApplications(applications: IApplications) {
        return Object.keys(applications).reduce((apps, appId) => {
            apps[appId] = {
                id: applications[appId].id,
                name: applications[appId].name,
                roles: applications[appId].roles.reduce((roles, role) => {
                    roles[role.roleId] = role.name;
                    return roles;
                }, {} as { roles: { [roleId: string]: string } })
            };
            return apps;
        }, {} as { roles: { [roleId: string]: string } });
    }

}
