<div mat-dialog-title>
    <h3>{{data.title | translate}}</h3>
</div>
<div *ngIf="data.content" mat-dialog-content>
    {{data.content | translate}}
</div>
<div *ngFor="let contentRow of data.contentRows" mat-dialog-content [innerHTML]="contentRow">
</div>
<div mat-dialog-actions fxLayoutAlign="center center" fxLayoutGap="10px">
    <button *ngIf="data.buttons.cancel" mat-button mat-raised-button mat-dialog-close="" color="primary"><span translate>common.cancel</span></button>
    <button *ngIf="data.buttons.ok" mat-button mat-raised-button mat-dialog-close="ok" color="accent"><span translate>common.ok</span></button>
</div>