import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatChipsModule,
    MatDatepickerModule,
    MatTooltipModule,
    MatProgressSpinnerModule
} from '@angular/material';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { FlexLayoutModule } from '@angular/flex-layout';

import { KsFormSetModule } from '../../shared/ks-formset/ks-formset.module';
import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';

import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { TestLoginPageComponent } from './components/testlogin-page/testlogin-page.component';
import { CallbackComponent } from './components/callback/callback.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatCheckboxModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule, MatChipsModule,
        MatDatepickerModule, MatTooltipModule, MatProgressSpinnerModule,
        MatMomentDateModule,

        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsFormSetModule,
        KsNavigationModule
    ],
    declarations: [
        LandingPageComponent,
        TestLoginPageComponent,
        CallbackComponent
    ],
    exports: [
        LandingPageComponent,
        TestLoginPageComponent,
        CallbackComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class BaseModule {
}
