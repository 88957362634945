import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Permissions, UserProfile } from 'src/app/models/auth-user';

import { AppConfigService } from 'src/app/services/app-config.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { IAMUser } from 'src/app/models/IAMUser';
import { ManagementService, UserSearchResponse } from 'src/app/services/management.service';

@Component({
    selector: 'app-my-account-page',
    templateUrl: './my-account-page.component.html',
    styleUrls: ['./my-account-page.component.scss']
})
export class MyAccountPageComponent implements OnInit {

    /**
     * Managed IAM user
     */
    user: IAMUser;

    /**
     * User profile
     */
    authUser: UserProfile;

    /**
     * User permissions, same as authService.userProfile.permissions
     */
    permissions: Permissions = {
        scope: '',
        modules: {},
        actions: {}
    };

    /**
     * List of organization users
     */
    users: Array<IAMUser>;
    loadingUsers: boolean;

    availableLanguages: Array<string>;

    state: {
        loading: boolean;
    };

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private managementService: ManagementService,
        private stateService: StateService
    ) {
        this.availableLanguages = ['da', 'de', 'en', 'es', 'fi', 'fr', 'it', 'nb', 'nl', 'pl', 'ru', 'sv', 'zh'];
    }

    ngOnInit() {
        this.state = {
            loading: false
        };
        setTimeout(() => {
            this.stateService.state.file = '';
            this.stateService.state.hasMenu = true;
            this.authUser = this.authService.userProfile;
            this.permissions = this.authUser.permissions;
            this.getUser();
            this.searchUsers();
        });
    }

    setLoading(loading) {
        this.state.loading = loading;
    }

    getUser() {
        // this.user = this.authUser;
    }

    searchUsers() {
        this.loadingUsers = true;
        const searchParams = {
            company: this.authUser.company,
            partnerId: this.authUser.partnerId
        };
        this.managementService.searchUsers(searchParams).subscribe((response: UserSearchResponse) => {
            this.users = response.items || [];
        }, (error) => {
        }).add(() => {
            this.loadingUsers = false;
        });
    }

    save() {
        console.log('my-account save');
    }

    cancel() {
        this.router.navigate(['']);
    }

}
