import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import {
    MatButtonModule, MatInputModule, MatFormFieldModule, MatDialogModule
} from '@angular/material';
import {FlexLayoutModule} from '@angular/flex-layout';

import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { ValueDialogComponent } from './components/value-dialog/value-dialog.component';
import { DiscardConfirmDialogComponent } from './components/discard-dialog/discard-confirm-dialog.component';

@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatFormFieldModule, MatDialogModule,
        FormsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule
    ],
    declarations: [
        ConfirmDialogComponent,
        ValueDialogComponent,
        DiscardConfirmDialogComponent
    ],
    entryComponents: [
        ConfirmDialogComponent,
        ValueDialogComponent,
        DiscardConfirmDialogComponent
    ],
    exports: [
    ]
})
export class DialogsModule {
}
