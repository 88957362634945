<mat-toolbar>
    <!-- Transclude buttons here from customer using this element -->
    <ng-content fxFlex></ng-content>


    <button mat-raised-button color="primary" *ngIf="hasCancel" (click)="emitCancel()">
        <span translate>common.cancel</span>
    </button>
    <button mat-raised-button color="accent" *ngIf="hasSave" [disabled]="!canSave" (click)="emitSave()">
        <span *ngIf="!isSaving" translate>common.save</span>
        <span *ngIf="isSaving"><mat-progress-bar
            color="primary" mode="indeterminate"></mat-progress-bar></span>
    </button>

</mat-toolbar>
