import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Components
import { CallbackComponent } from './modules/base/components/callback/callback.component';
import { LandingPageComponent } from './modules/base/components/landing-page/landing-page.component';
import { UserEditPageComponent } from './modules/management/components/user-edit-page/user-edit-page.component';
import { UsersListingPageComponent } from './modules/management/components/users-listing-page/users-listing-page.component';
import { TestLoginPageComponent } from './modules/base/components/testlogin-page/testlogin-page.component';

import { PortalPageComponent } from './modules/portal/components/portal-page/portal-page.component';
import { MyAccountPageComponent } from './modules/portal/components/my-account-page/my-account-page.component';

// Services
import { AuthService } from './services/auth.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';

const routes: Routes = [
    {
        path: 'landing',
        component: LandingPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'callback',
        component: CallbackComponent
    },
    {
        path: 'management',
        component: UsersListingPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'management/:id',
        component: UserEditPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'testlogin',
        component: TestLoginPageComponent
    },
    {
        path: 'portal',
        component: PortalPageComponent,
        canActivate: [AuthService]
    },
    {
        path: 'account',
        component: MyAccountPageComponent,
        canActivate: [AuthService]
    },
    {
        path: '**', redirectTo: 'landing'
    }
];
@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
