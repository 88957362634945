import * as _ from 'lodash';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfigService } from './app-config.service';
import { AuthService } from './auth.service';

import { CreateUserRequestBody, IAMUser } from '../models/IAMUser';
import { IUserLogs } from '../models/user-logs';

export interface UserSearchResponse {
    totalCount: number;
    items: Array<IAMUser>;
};

@Injectable()
export class ManagementService {

    BACKENDURL: string;
    MACHINERY_BACKENDURL: string;
    token: string;

    constructor(
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private http: HttpClient
    ) {
        this.BACKENDURL = this.appConfigService.config.iamBackendUrl;
        this.MACHINERY_BACKENDURL = this.appConfigService.config.machineryBackendUrl;
        this.token = this.authService.accessToken;
    }

    getUser(id: string): Observable<IAMUser> {
        console.log('getUser()', id);
        return this.http.get<IAMUser>(`${this.BACKENDURL}/users/${id}`, { headers: this._getHeaders() });
    }

    searchUsers(params): Observable<UserSearchResponse> {
        console.log('searchUsers', params);
        return this.http.get<UserSearchResponse>(`${this.BACKENDURL}/users`, { params, headers: this._getHeaders() });
    }

    createUser(user: CreateUserRequestBody) {
        return this.http.post(`${this.BACKENDURL}/users`, JSON.stringify(user), { headers: this._getHeaders() });
    }

    updateUser(id: string, user: IAMUser) {
        return this.http.put(`${this.BACKENDURL}/users/${id}`, JSON.stringify(user), { headers: this._getHeaders() });
    }

    deleteUser(id: string) {
        return this.http.delete(`${this.BACKENDURL}/users/${id}`, { headers: this._getHeaders() });
    }

    resetUserPassword(id: string) {
        return this.http.post<{ success: boolean }>(`${this.BACKENDURL}/users/reset-password/${id}`, null, { headers: this._getHeaders() })
    }

    getUserLogs(id: string): Observable<IUserLogs> {
        return this.http.get<IUserLogs>(`${this.BACKENDURL}/auth/logs/${id}`, { headers: this._getHeaders() });
    }


    _getHeaders() {
        return new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.token}`
        });
    }

    /**
     * Returns search params ?search=key:value;key2:value2
     * @deprecated
     * @param params key value pairs { key: value }
     */
    _getSearchString(params) {
        const queryStringParams = _.map(params, (p) => {
            return p.key + ':' + p.value;
        }).join(',');
        if (queryStringParams) {
            return `?search=${queryStringParams}`;
        } else {
            return '';
        }
    }
}
