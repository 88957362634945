export type IApplications = Record<IApplicationIds, IAMApplication>;

export type IApplicationIds =  'configurator' | 'calculators' | 'datastore' | 'eordering' | 'ewarranty' | 'iam' | 'machinery' | 'ordermanagement' | 'salesargumentation' | 'servicedoc' | 'softwarehub' | 'support' | 'userdoc' | 'pd-tool';

export interface IAMApplication {
    /**
     * Application id
     */
    id: string;

    /**
     * UI shown name for the application
     */
    name: string;

    /**
     * Disabled, cannot be changed in user-editing page
     * Only system admin can change
     */
    disabled?: boolean;

    /**
     * Image filename
     * /assets/images/services/[filename]
     */
    image: string;

    /**
     * Image style, rounded (with border radius) or square
     */
     imageStyle?: 'rouded' | 'square';

    /**
     * Environments of application
     * prod is required!
     */
    environments: {
        dev?: {
            startUrl: string;
            startUrls?: object;
        }
        stage?: {
            startUrl: string;
            startUrls?: object;
        }
        prod: {
            /**
             * Start url of applications
             */
            startUrl: string;
            startUrls?: object;
        }
    };

    /**
     * Allow super user add access
     * If set true, customer super user can add access for the application
     */
    allowSuperUserAddAccess?: boolean;

    /**
     * Allow super user role change
     * If set true, customer super user can change external roles
     */
    allowSuperUserRoleChange?: boolean;

    /**
     * Disabled for non internal users
     */
    internalOnly?: boolean;

    /**
     * Public access to applications, only link to url in services listing
     * Role or permission are not required and are not maintained in MyKemppi
     */
    publicAccess?: boolean;

    /**
     * List of roles
     */
    roles: Array<IAMApplicationRole>;

    /**
     * Application specific attributes.
     * Shown and editable in IAM UI - user edit page.
     * 
     * Passed from IAM to end application during login.
     * 
     * FROM: IAM user.data.appAttributes[applicationId] =>
     * TO: APP user.custom_attributes.attributes
     */
    attributes?: Array<IAMApplicationAttribute>;
}

export interface IAMApplicationRole {
    /**
     * Role id
     * Unique within the application
     * Changes to these might need changes to client applications role and permission handlings
     */
    roleId: string;

    /**
     * UI shown name for the role
     */
    name: string;

    /**
     * External role
     * - Lower level role
     * - Assign for Dealer / Distributor / Customer users
     * - Can be assigned by IAM SUB admins
     */
    external?: boolean;

    /**
     * Internal role
     * - Higher level role
     * - Assign only for Kemppi Oy / Subsidiary users
     * - Can only be assigned by IAM KOY Admins
     */
    internal?: boolean;

    /**
     * Role description
     * Shown with role selection
     */
    description?: string;
}

export interface IAMApplicationAttribute {
    key: string;
    name: string;
    attributeType: 'text' | 'checkbox' | 'select' | 'multiselect';
    selectValues?: Array<string>;
    required?: boolean;
    disabled?: boolean;
    description?: string;
    allowSuperUserAddAccess?: boolean;
}

/**
 * Application ids
 * Also determines the order of applications shown in services listing
 */
export const ApplicationIds: IApplicationIds[] = [
    'configurator',
    'calculators',
    'datastore',
    'eordering', // Will be replaced to eordering.kemppi and eordering.trafimet in portal service listing
    'ewarranty',
    'iam',
    'machinery',
    'ordermanagement',
    'salesargumentation',
    'servicedoc',
    'softwarehub',
    'support',
    'userdoc',
    'pd-tool'
];

/**
 * Applications
 * Manage application roles in this!
 */
export const Applications: IApplications = {
    // CONFIGURATOR
    'configurator': {
        id: 'configurator',
        name: 'Configurator',
        image: 'configurator.png',
        environments: {
            dev: { startUrl: 'https://configurator.dev.cloud.kemppi.com' },
            stage: { startUrl: 'https://configurator.stage.cloud.kemppi.com' },
            prod: { startUrl: 'https://configurator.kemppi.com' }
        },
        allowSuperUserAddAccess: true,
        roles: [
            // External
            { roleId: 'ext_user',   name: 'External User',  external: true },
            // Internal
            { roleId: 'int_user',   name: 'Internal User',  internal: true },
            { roleId: 'sys_admin',  name: 'Admin',          internal: true, description: 'System administrator' }
        ]
    },

    // CALCULATORS
    'calculators': {
        id: 'calculators',
        name: 'Contact tip calculator',  
        image: 'calculators.png',
        publicAccess: true,
        environments: {
            dev: { startUrl: 'http://kemppi-calculators-dev-frontend.s3-website-eu-west-1.amazonaws.com' },
            stage: { startUrl: 'https://calculators.kemppi.com' },
            prod: { startUrl: 'https://calculators.kemppi.com' },
        },
        roles: []
    },

    // DATASTORE
    'datastore': {
        id: 'datastore',
        name: 'Datastore',
        image: 'datastore.jpg',
        environments: {
            // Added /login/auth0 to straight redirect user to MyKemppi auth0 login without selection
            dev: { startUrl: 'https://datastoretest.kemppi.com/login/auth0' },
            stage: { startUrl: 'https://datastoretest.kemppi.com/login/auth0' },
            prod: { startUrl: 'https://datastore.kemppi.com/login/auth0' }
        },
        allowSuperUserAddAccess: true,
        roles: [
            // External
            { roleId: 'Guest',          name: 'Guest',        external: true, description: 'Can download free firmware only' },
            // Internal
            { roleId: 'Admin',                  name: 'Admin',                internal: true, description: 'System administrator' },
            { roleId: 'Logistics',              name: 'Logistics',            internal: true },
            { roleId: 'WeldingEngineer',        name: 'Welding Engineer',     internal: true, description: 'Can add and modify softwares' },
            { roleId: 'SubsidiaryMainuser',     name: 'Subsidiary Mainuser',  internal: true, description: 'Can create orders for customers in same company, Can create unbilled orders' },
            { roleId: 'Developer',              name: 'Developer',            internal: true, description: 'Use only for external consultant software developers, permissions can change.' }
        ]
    },

    // E-WARRANTY
    'ewarranty': {
        id: 'ewarranty',
        name: 'e-Warranty',
        image: 'ewarranty.png',
        environments: {
            dev: { startUrl: 'https://warranty.dev.cloud.kemppi.com' },
            stage: { startUrl: 'https://warranty.stage.cloud.kemppi.com' },
            prod: { startUrl: 'https://ewarranty.kemppi.com' }
        },
        allowSuperUserAddAccess: true,
        roles: [
            // External
            { roleId: 'cus_dealer',             name: 'Dealer',               external: true, description: 'Can create claims and registrations' },
            { roleId: 'cus_dealer_wo_workshop', name: 'Dealer w/o workshop',  external: true, description: 'Can create registrations' },
            { roleId: 'cus_direct',             name: 'Direct customer',      external: true, description: 'Can create claims' },
            { roleId: 'cus_distributor',        name: 'Distributor',          external: true, description: 'Can create claims and registrations' },
            { roleId: 'cus_workshop',           name: 'Workshop only',        external: true, description: 'Can create claims' },
            // Internal
            { roleId: 'sys_admin',      name: 'Admin',                internal: true, description: 'System administrator' },
            { roleId: 'koy_user',       name: 'Kemppi Oy',            internal: true, description: 'Global access, Accepts and rejects claims globally' },
            { roleId: 'koy_logistics',  name: 'Kemppi Oy logistics',  internal: true, description: 'Global access, Handles logistics' },
            { roleId: 'koy_readonly',   name: 'Kemppi Oy readonly',   internal: true, description: 'Global access, Readonly' },
            { roleId: 'sub_user',       name: 'Subsidiary',           internal: true, description: 'Company access, Accepts and rejects claims in same company' },
            { roleId: 'sub_readonly',   name: 'Subsidiary readonly',  internal: true, description: 'Company access, Readonly' }
        ],
        attributes: [
            { key: 'noPrices', name: 'No prices', attributeType: 'checkbox', description: 'Cannot see claim prices', allowSuperUserAddAccess: true }
        ]
    },

    // E-ORDERING
    'eordering': {
        id: 'eordering',
        name: 'e-Ordering',
        image: 'eordering.png',
        environments: {
            // See subsystems eordering.kemppi and eordering.trafimet
            // eOrdering start urls changes by users company
            dev: {
                startUrl: 'https://kemppi-fi-dev.parttrap.com',
                startUrls: {
                    '100': 'https://kemppi-fi-dev.parttrap.com/user/externallogin/Kemppi',
                    '109': 'https://kemppi-au-dev.parttrap.com/user/externallogin/Kemppi',
                    '130': 'https://kemppi-se-dev.parttrap.com/user/externallogin/Kemppi',
                    '140': 'https://kemppi-no-dev.parttrap.com/user/externallogin/Kemppi',
                    '150': 'https://kemppi-dk-dev.parttrap.com/user/externallogin/Kemppi',
                    '160': 'https://kemppi-de-dev.parttrap.com/user/externallogin/Kemppi',
                    '170': 'https://kemppi-nl-dev.parttrap.com/user/externallogin/Kemppi',
                    '180': 'https://kemppi-uk-dev.parttrap.com/user/externallogin/Kemppi',
                    '190': 'https://kemppi-fr-dev.parttrap.com/user/externallogin/Kemppi',
                    '210': 'https://kemppi-pl-dev.parttrap.com/user/externallogin/Kemppi',
                    '220': 'https://kemppi-ru-dev.parttrap.com/user/externallogin/Kemppi',
                    '240': 'https://kemppi-cn-dev.parttrap.com/user/externallogin/Kemppi',
                    '260': 'https://kemppi-in-dev.parttrap.com/user/externallogin/Kemppi',
                    '290': 'https://kemppi-it-dev.parttrap.com/user/externallogin/Kemppi'
                }
            },
            stage: { 
                startUrl: 'https://kemppi-fi-staging.parttrap.com',
                startUrls: {
                    '100': 'https://kemppi-fi-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '109': 'https://kemppi-au-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '130': 'https://kemppi-se-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '140': 'https://kemppi-no-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '150': 'https://kemppi-dk-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '160': 'https://kemppi-de-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '170': 'https://kemppi-nl-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '180': 'https://kemppi-uk-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '190': 'https://kemppi-fr-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '210': 'https://kemppi-pl-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '220': 'https://kemppi-ru-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '240': 'https://kemppi-cn-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '260': 'https://kemppi-in-staging.parttrap.com/user/externallogin/Kemppi-staging',
                    '290': 'https://kemppi-it-staging.parttrap.com/user/externallogin/Kemppi-staging'
                }
            },
            prod: {
                startUrl: 'https://eordering-fi.kemppi.com',
                startUrls: {
                    '100': 'https://eordering-fi.kemppi.com/user/externallogin/Kemppi',
                    '109': 'https://eordering-au.kemppi.com/user/externallogin/Kemppi',
                    '130': 'https://eordering-se.kemppi.com/user/externallogin/Kemppi',
                    '140': 'https://eordering-no.kemppi.com/user/externallogin/Kemppi',
                    '150': 'https://eordering-dk.kemppi.com/user/externallogin/Kemppi',
                    '160': 'https://eordering-de.kemppi.com/user/externallogin/Kemppi',
                    '170': 'https://eordering-nl.kemppi.com/user/externallogin/Kemppi',
                    '180': 'https://eordering-uk.kemppi.com/user/externallogin/Kemppi',
                    '190': 'https://eordering-fr.kemppi.com/user/externallogin/Kemppi',
                    '210': 'https://eordering-pl.kemppi.com/user/externallogin/Kemppi',
                    '220': 'https://eordering-ru.kemppi.com/user/externallogin/Kemppi',
                    '240': 'https://eordering-cn.kemppi.com/user/externallogin/Kemppi',
                    '260': 'https://eordering-in.kemppi.com/user/externallogin/Kemppi',
                    '290': 'https://eordering-it.kemppi.com/user/externallogin/Kemppi'
                }
            }
        },
        allowSuperUserAddAccess: true,
        allowSuperUserRoleChange: true,
        roles: [
            // External
            { roleId: '16',     name: 'Standard User',          external: true, description: 'Can view, put in cart and place order. Can view order and invoice history.' },
            { roleId: '1006',   name: 'Add to cart w/o prices', external: true, description: 'Can view, w/o price, able to add to cart, but not send order or see order and invoice history.' },
            { roleId: '1007',   name: 'Add to cart w/ prices',  external: true, description: 'Can view products, w/ prices, able to add to cart, but not send order or see order and invoice history.' },
            { roleId: '1008',   name: 'No cart, viewonly',      external: true, description: 'Can only view product information w/o prices and add to cart' },
            // Internal
            { roleId: '1004',   name: 'Internal tester',       internal: true, description: 'Same as standard customer, but able to change customer ID' }
        ]
    },

    // MYKEMPPI IAM
    'iam': {
        id: 'iam',
        name: 'MyKemppi portal',
        image: 'iam.png',
        environments: {
            dev: { startUrl: 'https://mykemppi.dev.cloud.kemppi.com' },
            stage: { startUrl: 'https://mykemppi.stage.cloud.kemppi.com' },
            prod: { startUrl: 'https://my.kemppi.com' }
        },
        allowSuperUserAddAccess: true,
        roles: [
            // External
            { roleId: 'cus_user',   name: 'User',                   external: true, description: 'Can see portal, list of allowed application based on user roles' },
            { roleId: 'cus_super',   name: 'Customer Super User',   external: true, description: 'Can manage own users' },
            // Internal
            { roleId: 'sys_admin',   name: 'System Admin',     internal: true, description: 'System administrator' },
            { roleId: 'koy_admin',   name: 'Kemppi Oy Admin',  internal: true, description: 'Can manage users globally' },
            { roleId: 'sub_admin',   name: 'Subsidiary Admin', internal: true, description: 'Can manage users in same company' }
        ]
    },

    // MACHINERY
    'machinery': {
        id: 'machinery',
        name: 'Machinery',
        image: 'iam.png',
        internalOnly: true,
        environments: {
            dev: { startUrl: 'https://machinery.dev.cloud.kemppi.com' },
            stage: { startUrl: 'https://machinery.stage.cloud.kemppi.com' },
            prod: { startUrl: 'https://machinery.kemppi.com' }
        },
        roles: [
            // External - NO EXTERNALS
            // Internal
            { roleId: 'sys_admin',      name: 'Admin',                  internal: true, description: 'System administrator' },
            { roleId: 'koy_user',       name: 'Kemppi Oy',              internal: true, description: 'Global access' },
            { roleId: 'koy_readonly',   name: 'Kemppi Oy readonly',     internal: true, description: 'Global access, Readonly' },
            { roleId: 'sub_user',       name: 'Subsidiary',             internal: true, description: 'Company access, Readonly' },
            { roleId: 'sub_readonly',   name: 'Subsidiary readonly',    internal: true, description: 'Company access, Readonly' }
        ]
    },

    // ORDER MANAGEMENT
    'ordermanagement': {
        id: 'ordermanagement',
        name: 'Order Management',
        image: 'iam.png',
        internalOnly: true,
        disabled: true,
        environments: {
            dev: { startUrl: 'https://om.dev.cloud.kemppi.com' },
            stage: { startUrl: 'https://om.stage.cloud.kemppi.com' },
            prod: { startUrl: 'https://om.kemppi.com' }
        },
        roles: [
            // External - NO EXTERNALS
            // Internal
            { roleId: 'sys_admin',      name: 'Admin',                  internal: true, description: 'System administrator' },
            { roleId: 'koy_user',       name: 'Kemppi Oy',              internal: true, description: 'Global access' },
            { roleId: 'koy_readonly',   name: 'Kemppi Oy readonly',     internal: true, description: 'Global access, Readonly' },
            { roleId: 'sub_user',       name: 'Subsidiary',             internal: true, description: 'Company access, Readonly' },
            { roleId: 'sub_readonly',   name: 'Subsidiary readonly',    internal: true, description: 'Company access, Readonly' }
        ]
    },

    // SALES ARGUMENTATION
    'salesargumentation': {
        id: 'salesargumentation',
        name: 'Sales Argumentation',
        image: 'iam.png',
        internalOnly: true,
        environments: {
            dev: { startUrl: 'https://kemppi-sales-app.web.app' },
            stage: { startUrl: 'https://kemppi-sales-app.web.app' },
            prod: { startUrl: 'https://kemppi-sales-app.web.app' }
        },
        roles: [
            // External - NO EXTERNALS
            // Internal
            { roleId: 'viewer',       name: 'Viewer',       internal: true },
            { roleId: 'editor',       name: 'Editor',       internal: true },
            { roleId: 'admin',        name: 'Admin',        internal: true, description: 'System administrator' }
        ]
    },

    // SERVICEDOC
    'servicedoc': {
        id: 'servicedoc',
        name: 'Servicedoc',
        image: 'servicedoc.png',
        environments: {
            dev: { startUrl: 'https://dev.servicedoc.kemppi.com' },
            stage: { startUrl: 'https://dev.servicedoc.kemppi.com' },
            prod: { startUrl: 'https://servicedoc.kemppi.com' }
        },
        roles: [
            // External
            { roleId: 'cus_guest',  name: 'Guest',  external: true, description: 'Can access service documents' }
            // Internal - NO INTERNALS
        ]
    },

    // SOFTWARE HUB
    'softwarehub': {
        id: 'softwarehub',
        name: 'Software Hub',
        image: 'swhub.png',
        environments: {
            dev: { startUrl: 'https://swhub.dev.cloud.kemppi.com' },
            stage: { startUrl: 'https://swhub.stage.cloud.kemppi.com' },
            prod: { startUrl: 'https://swhub.kemppi.com' }
        },
        allowSuperUserAddAccess: true,
        roles: [
            // External
            { roleId: 'cus_dealer',         name: 'Dealer',         external: true, description: 'Can add own mobile devices, Can assign own licenses' },
            { roleId: 'cus_distributor',    name: 'Distributor',    external: true, description: 'Can add own mobile devices, Can assign own licenses' },
            // Internal
            { roleId: 'sys_admin',          name: 'Admin',              internal: true, description: 'System administrator' },
            { roleId: 'koy_user',           name: 'Kemppi Oy',          internal: true, description: 'Global access, Can manage softwares, Can create unbilled orders, Can add mobile devices, Can assign licenses' },
            { roleId: 'koy_readonly',       name: 'Kemppi Oy readonly', internal: true, description: 'Global access, Can assign licenses' },
            // { roleId: 'sub_mainuser',       name: 'Subsidiary Mainuser',internal: true, description: 'Company access, Can create unbilled orders' },
            { roleId: 'sub_user',           name: 'Subsidiary',         internal: true, description: 'Company access, Can create unbilled orders, Can add mobile devices, Can assign licenses' },
            { roleId: 'sub_readonly',       name: 'Subsidiary readonly',internal: true, description: 'Company access, Can assign licenses' }
        ]
    },

    // KEMPPI SUPPORT
    'support': {
        id: 'support',
        name: 'Kemppi support (JIRA)',
        image: 'Jira Service Desk-icon-blue.svg',
        publicAccess: true,
        environments: {
            // Kemppi support start urls changes by users company
            dev: {
                startUrl: 'https://kemppi.atlassian.net/servicedesk/customer/portals',
                startUrls: {
                    '100': 'https://kemppi.atlassian.net/servicedesk/customer/portals',
                    '109': 'https://kemppi.atlassian.net/servicedesk/customer/portal/32',
                    '130': 'https://kemppi.atlassian.net/servicedesk/customer/portal/33',
                    '140': 'https://kemppi.atlassian.net/servicedesk/customer/portal/34',
                    '150': 'https://kemppi.atlassian.net/servicedesk/customer/portal/35',
                    '160': 'https://kemppi.atlassian.net/servicedesk/customer/portal/36',
                    '170': 'https://kemppi.atlassian.net/servicedesk/customer/portal/37',
                    '180': 'https://kemppi.atlassian.net/servicedesk/customer/portal/38',
                    '190': 'https://kemppi.atlassian.net/servicedesk/customer/portal/39',
                    '210': 'https://kemppi.atlassian.net/servicedesk/customer/portal/40',
                    '220': 'https://kemppi.atlassian.net/servicedesk/customer/portal/41',
                    '240': 'https://kemppi.atlassian.net/servicedesk/customer/portal/42',
                    '260': 'https://kemppi.atlassian.net/servicedesk/customer/portal/43',
                    '290': 'https://kemppi.atlassian.net/servicedesk/customer/portal/44'
                }
            },
            stage: { 
                startUrl: 'https://kemppi.atlassian.net/servicedesk/customer/portals',
                startUrls: {
                    '100': 'https://kemppi.atlassian.net/servicedesk/customer/portals',
                    '109': 'https://kemppi.atlassian.net/servicedesk/customer/portal/32',
                    '130': 'https://kemppi.atlassian.net/servicedesk/customer/portal/33',
                    '140': 'https://kemppi.atlassian.net/servicedesk/customer/portal/34',
                    '150': 'https://kemppi.atlassian.net/servicedesk/customer/portal/35',
                    '160': 'https://kemppi.atlassian.net/servicedesk/customer/portal/36',
                    '170': 'https://kemppi.atlassian.net/servicedesk/customer/portal/37',
                    '180': 'https://kemppi.atlassian.net/servicedesk/customer/portal/38',
                    '190': 'https://kemppi.atlassian.net/servicedesk/customer/portal/39',
                    '210': 'https://kemppi.atlassian.net/servicedesk/customer/portal/40',
                    '220': 'https://kemppi.atlassian.net/servicedesk/customer/portal/41',
                    '240': 'https://kemppi.atlassian.net/servicedesk/customer/portal/42',
                    '260': 'https://kemppi.atlassian.net/servicedesk/customer/portal/43',
                    '290': 'https://kemppi.atlassian.net/servicedesk/customer/portal/44'
                }
            },
            prod: {
                startUrl: 'https://kemppi.atlassian.net/servicedesk/customer/portals',
                startUrls: {
                    '100': 'https://kemppi.atlassian.net/servicedesk/customer/portals',
                    '109': 'https://kemppi.atlassian.net/servicedesk/customer/portal/32',
                    '130': 'https://kemppi.atlassian.net/servicedesk/customer/portal/33',
                    '140': 'https://kemppi.atlassian.net/servicedesk/customer/portal/34',
                    '150': 'https://kemppi.atlassian.net/servicedesk/customer/portal/35',
                    '160': 'https://kemppi.atlassian.net/servicedesk/customer/portal/36',
                    '170': 'https://kemppi.atlassian.net/servicedesk/customer/portal/37',
                    '180': 'https://kemppi.atlassian.net/servicedesk/customer/portal/38',
                    '190': 'https://kemppi.atlassian.net/servicedesk/customer/portal/39',
                    '210': 'https://kemppi.atlassian.net/servicedesk/customer/portal/40',
                    '220': 'https://kemppi.atlassian.net/servicedesk/customer/portal/41',
                    '240': 'https://kemppi.atlassian.net/servicedesk/customer/portal/42',
                    '260': 'https://kemppi.atlassian.net/servicedesk/customer/portal/43',
                    '290': 'https://kemppi.atlassian.net/servicedesk/customer/portal/44'
                }
            }
        },
        roles: []
    },

    // USERDOC
    'userdoc': {
        id: 'userdoc',
        name: 'Userdoc',
        image: 'userdoc.png',
        publicAccess: true,
        environments: {
            dev: { startUrl: 'https://userdoc.kemppi.com/Default.htm' },
            stage: { startUrl: 'https://userdoc.kemppi.com/Default.htm' },
            prod: { startUrl: 'https://userdoc.kemppi.com/Default.htm' }
        },
        roles: []
    },

    // PRODUCT DEFINITION TOOL
    'pd-tool': {
        id: 'pd-tool',
        name: 'Product feature tool',
        image: 'pd-tool.png',
        internalOnly: true,
        allowSuperUserAddAccess: true,
        environments: {
            dev: { startUrl: 'https://pd-tool.dev.cloud.kemppi.com' },
            stage: { startUrl: 'https://pd-tool.stage.cloud.kemppi.com' },
            prod: { startUrl: 'https://pf.kemppi.com' }
        },
        roles: [
            // External - NO EXTERNALS
            // Internal
            { roleId: 'int_user', name: 'Internal user', internal: true, description: 'Internal Kemppi employees' }
        ]
    },
};
