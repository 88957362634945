import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Company, Companies } from 'src/app/models/company';
import { Permissions, UserProfile } from 'src/app/models/auth-user';

import { AppConfigService } from 'src/app/services/app-config.service';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { ApplicationIds, Applications, IApplications, IApplicationIds } from 'src/app/models/applications';

@Component({
    selector: 'app-portal-page',
    templateUrl: './portal-page.component.html',
    styleUrls: ['./portal-page.component.scss']
})
export class PortalPageComponent implements OnInit {

    /**
     * Current running environment
     * 'local' | 'dev' | 'stage' | 'prod'
     */
    public environment: string;

    /**
     * Applications ids Array<string>
     */
    public applicationIds: IApplicationIds[];

    /**
     * Disabled applications
     */
    public disabledApplicationIds: IApplicationIds[] = [];

    /**
     * Applications objects { 'appId': {...} }
     */
    public applications: IApplications;

    /**
     * List of companies
     */
    public companies: Array<Company> = [];

    /**
     * User profile
     */
    public authUser: UserProfile;

    /**
     * User permissions, same as authService.userProfile.permissions
     */
    public permissions: Permissions = {
        scope: '',
        modules: {},
        actions: {}
    };

    public userServices: {
        [appId: string]: boolean;
    };
    public userServicesEOrdering?: Array<string>;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private appConfigService: AppConfigService,
        private authService: AuthService,
        private stateService: StateService
    ) {
        this.companies = Companies;
        this.applicationIds = [];
        for (const appId of ApplicationIds) {
            this.applicationIds.push(appId);
        }
        this.applications = Applications;
    }
    ngOnInit() {
        const environment = this.appConfigService.config.environment;
        this.environment = environment === 'local' ? 'dev' : environment;

        setTimeout(() => {
            this.stateService.state.file = '';
            this.stateService.state.hasMenu = true;
            this.authUser = this.authService.userProfile;
            this.permissions = this.authUser.permissions;

            const eordering = Object.keys(this.authUser.eordering || {});
            this.userServices = (this.authUser.applications || []).reduce((services, appId) => {
                services[appId] = true;
                return services;
            }, {});
        });
    }

    navigateToService(appId: IApplicationIds) {
        if (this.disabledApplicationIds.length && this.disabledApplicationIds.includes(appId)) {
            return;
        }
        if (this.applications[appId]
            && this.applications[appId].environments
            && this.applications[appId].environments[this.environment]
            && (this.userServices[appId] || this.applications[appId].publicAccess)
        ) {
            if (this.applications[appId].environments[this.environment].startUrls
                    && this.authUser.company
                    && this.applications[appId].environments[this.environment].startUrls[this.authUser.company]
            ) {
                // Company based start url's?
                console.log('COMPANY BASED REDIRECTION', this.authUser.company);
                window.open(this.applications[appId].environments[this.environment].startUrls[this.authUser.company], '_blank');
            } else {
                window.open(this.applications[appId].environments[this.environment].startUrl, '_blank');
            }
        } else {
            console.error('navigateToService', { appId, env: this.environment });
        }
    }
    /**
     * Filter visible applications
     * @param disabledApplications Applications which should be diabled no matter if user can access them or not 
     */
    filterApplications(disabledApplications: IApplicationIds[]) {
        this.disabledApplicationIds = disabledApplications || [];
    }
}
