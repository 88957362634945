<div class="main">
    <div class="full" fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="center center">
        <fieldset class="login-container">
            <legend>Test login</legend>
            <div fxLayout="column">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field>
                        <mat-label>Id</mat-label>
                        <input matInput [(ngModel)]="loginParams.id" required>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Name</mat-label>
                        <input matInput [(ngModel)]="loginParams.name" required>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input matInput [(ngModel)]="loginParams.email" required>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Role</mat-label>
                        <mat-select [(value)]="loginParams.role" required>
                            <mat-option value="sys_admin">System admin</mat-option>
                            <mat-option value="koy_admin">Kemppi Oy admin</mat-option>
                            <mat-option value="sub_admin">Subsidiary admin</mat-option>
                            <mat-option value="koy_user">Kemppi user</mat-option>
                            <mat-option value="sub_user">Subsidiary user</mat-option>
                            <mat-option value="cus_user">Customer user</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="20px">
                    <mat-form-field>
                        <mat-label>Partner id</mat-label>
                        <input matInput [(ngModel)]="loginParams.partnerId" (change)="validateAndParseUserType()" required>
                        <mat-hint class="weak">({{ loginParams.userType }})</mat-hint>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Company</mat-label>
                        <mat-select [(value)]="loginParams.company" (selectionChange)="validateAndParseUserType()" required>
                            <mat-option *ngFor="let companyCode of companyCodes" [value]="companyCode">{{companyCode}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                    <mat-form-field>
                        <mat-label>Applications</mat-label>
                        <mat-select [multiple]="true" [(value)]="loginParams.applications">
                            <mat-option *ngFor="let appId of applicationIds" [value]="appId">{{appId}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>Password</mat-label>
                        <input matInput [(ngModel)]="loginParams.password" type="password" (keydown.enter)="login()">
                    </mat-form-field>
                </div>
                <div fxLayout="row" fxLayoutGap="20px" fxLayoutAlign="start center">
                    <button mat-raised-button color="accent" (click)="login()">
                        <mat-icon>lock</mat-icon>
                        LOGIN
                    </button>
                    <mat-progress-spinner matSuffix *ngIf="loading" [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
                </div>
                <div fxLayout="row" class="warn">
                    {{ loginError }}
                </div>
            </div>
        </fieldset>
    </div>
</div>
