export const Countries = [
    "AALAND" ,
    "AFGANISTAN" ,
    "ALBANIA" ,
    "ALGERIA" ,
    "AMERICAN SAMOA" ,
    "ANDORRA" ,
    "ANGOLA" ,
    "ANGUILLA" ,
    "ANTARCTICA" ,
    "ANTIGUA AND BARBUDA" ,
    "ARGENTINA" ,
    "ARMENIA" ,
    "ARUBA" ,
    "AUSTRALIA" ,
    "AUSTRIA" ,
    "AZERBAIJAN" ,
    "BAHAMAS" ,
    "BAHRAIN" ,
    "BANGLADESH" ,
    "BARBADOS" ,
    "BELARUS" ,
    "BELGIUM" ,
    "BELIZE" ,
    "BENIN" ,
    "BERMUDA" ,
    "BHUTAN" ,
    "BOLIVIA" ,
    "BOSNIA AND HERZEGOVINA" ,
    "BOTSWANA" ,
    "BOUVET ISLAND" ,
    "BRAZIL" ,
    "BRITISH INDIAN OCEAN TERRITORY" ,
    "BRUNEI DARUSSLAM" ,
    "BUINEA-BISSAU" ,
    "BULGARIA" ,
    "BURKINA FASO" ,
    "BURUNDI" ,
    "CAMBODIA" ,
    "CAMERON" ,
    "CANADA" ,
    "CANARY ISLANDS" ,
    "CAPE VERDE" ,
    "CAYMAN ISLANDS" ,
    "CENTRAL AFRICAN REPUBLIC" ,
    "CHILE" ,
    "CHINA" ,
    "CHRISTMAS ISLAND" ,
    "COCOS (KEELING) ISLANDS" ,
    "COLOMBIA" ,
    "COMOROS" ,
    "CONGO" ,
    "CONGO, THE DEMOCRATIC REPUBLIC" ,
    "COOK ISLAND" ,
    "COSTA RICA" ,
    "CROATIA" ,
    "CUBA" ,
    "CYPRUS" ,
    "CZECH REPUBLIC" ,
    "DENMARK" ,
    "DJIBOUTI" ,
    "DOMINICA" ,
    "DOMINICAN REPUBLIC" ,
    "EAST TIMOR" ,
    "ECUARDOR" ,
    "EGYPT" ,
    "EL SALVADOR" ,
    "ENGLAND",
    "EQUATORIAL GUINEA" ,
    "ERITREA" ,
    "ESTONIA" ,
    "ETHIOPIA" ,
    "FALKLAND ISLANDS (MALVINAS)" ,
    "FAROE ISLANDS" ,
    "FIJI" ,
    "FINLAND" ,
    "FRANCE" ,
    "FRANCE, METROPOLITAN" ,
    "FRENCH GUIANA" ,
    "FRENCH POLYNESIA" ,
    "FRENCH SOUTHERN TERRITORIES" ,
    "GABON" ,
    "GAMBIA" ,
    "GEORGIA" ,
    "GERMANY" ,
    "GHANA" ,
    "GIBRALTAR" ,
    "GREECE" ,
    "GREENLAND" ,
    "GRENADA" ,
    "GUADELOUPE" ,
    "GUAM" ,
    "GUATEMALA" ,
    "GUERNSEY" ,
    "GUINEA" ,
    "GUYANA" ,
    "HAITI" ,
    "HEARD ISLAND AND MCDONALD ISLANDS" ,
    "HOLY SEE (VATICAN CITY STATE)" ,
    "HONDURAS" ,
    "HONG KONG" ,
    "HUNGARY" ,
    "ICELAND" ,
    "INDIA" ,
    "INDONESIA" ,
    "IRAN, ISLAMIC REPUBLIC OF" ,
    "IRAQ" ,
    "IRELAND" ,
    "ISLE OF MAN" ,
    "ISRAEL" ,
    "ITALY" ,
    "IVORY COAST" ,
    "JAMAICA" ,
    "JAPAN" ,
    "JERSEY" ,
    "JORDAN" ,
    "KAZAKHSTAN" ,
    "KENIA" ,
    "KIRIBATI" ,
    "KOREA, DEMOCRATIC PEOPLE'S REPUBLIC OF" ,
    "KOREA, REPUBLIC OF" ,
    "KOSOVO (NOT ISO)" ,
    "KUWAIT" ,
    "KYRGYZSTAN" ,
    "LAO PEOPLE'S DEMOCRATIC REPUBLIC" ,
    "LATVIA" ,
    "LEBANON" ,
    "LESOTHO" ,
    "LIBERIA" ,
    "LIBYAN ARAB JAMAHIRIYA" ,
    "LICHTENSTEIN" ,
    "LITHUANIA" ,
    "LUXEMBOURG" ,
    "MACAU" ,
    "MACEDONIA, THE FORMER YUGOSLAV REPUBLIC" ,
    "MADAGASKAR" ,
    "MALAWI" ,
    "MALAYSIA" ,
    "MALEDIVES" ,
    "MALI" ,
    "MALTA" ,
    "MARSHALL ISLANDS" ,
    "MARTINIQUE" ,
    "MAURITANIA" ,
    "MAURITIUS" ,
    "MAYOTTE" ,
    "MEXICO" ,
    "MICRONESIA, FEDERATED STATES" ,
    "MOLDOVA, REPUBLIC OF" ,
    "MONACO" ,
    "MONGOLIA" ,
    "MONTENEGRO" ,
    "MONTSERRAT" ,
    "MOROCCO" ,
    "MOZAMBIQUE" ,
    "MYANMAR" ,
    "NAMIBIA" ,
    "NAURU" ,
    "NEPAL" ,
    "NETHERLANDS" ,
    "NEW CALEDONIA" ,
    "NEW ZEALAND" ,
    "NICARAGUA" ,
    "NIGER" ,
    "NIGERIA" ,
    "NIUE" ,
    "NORFOLK ISLAND" ,
    "NORTHERN MARIANA ISLANDS" ,
    "NORWAY" ,
    "OMAN" ,
    "PAKISTAN" ,
    "PALAU" ,
    "PALESTINIAN TERRITORY, OCCUPIED" ,
    "PANAMA" ,
    "PAPUA NEW GUINEA" ,
    "PARAGUAY" ,
    "PERU" ,
    "PHILIPPINES" ,
    "PITCAIRN" ,
    "POLAND" ,
    "PORTUGAL" ,
    "PUERTO RICO" ,
    "QATAR" ,
    "REUNION" ,
    "ROMANIA" ,
    "RUSSIAN FEDERATION" ,
    "RWANDA" ,
    "SAINT HELENA" ,
    "SAINT KITTS AND NEVIS" ,
    "SAINT LUCIA" ,
    "SAINT PIERRE AND MIQUELON" ,
    "SAINT VINCENT AND THE GRENADINES" ,
    "SAMOA" ,
    "SAN MARINO" ,
    "SAO TOME AND PRINCIPE" ,
    "SAUDI ARABIA" ,
    "SCOTLAND",
    "SENEGAL" ,
    "SERBIA" ,
    "SEYCHELLES" ,
    "SIERRA LEONE" ,
    "SINGAPORE" ,
    "SLOVAKIA" ,
    "SLOVENIA" ,
    "SOLOMON ISLANDS" ,
    "SOMALIA" ,
    "SOUTH AFRICA" ,
    "SOUTH GEORGIA AND SOUTH SANDWICH ISLANDS" ,
    "SPAIN" ,
    "SRI LANKA" ,
    "SUDAN" ,
    "SURINAME" ,
    "SVALBARD AND JAN MAYEN" ,
    "SWAZILAND" ,
    "SWEDEN" ,
    "SWITZERLAND" ,
    "SYRIAN ARAB REPUBLIC" ,
    "TAIWAN, REPUBLIC OF" ,
    "TAJIKISTAN" ,
    "TANZANIA, UNITED REPUBLIC OF" ,
    "THAILAND" ,
    "TOGO" ,
    "TOKELAU" ,
    "TONGA" ,
    "TRINIDAD AND TOBAGO" ,
    "TUNISIA" ,
    "TURKEY" ,
    "TURKMENISTAN" ,
    "TURKS AND CAICOS ISLANDS" ,
    "TUVALU" ,
    "UGANDA" ,
    "UKRAINE" ,
    "UNITED ARAB EMIRATES" ,
    "UNITED KINGDOM" ,
    "UNITED STATES" ,
    "UNITED STATES MINOR OUTLYING ISLANDS" ,
    "URUGUAY" ,
    "UZBEKISTAN" ,
    "VANUATU" ,
    "VENEZUELA" ,
    "VIETNAM" ,
    "VIRGIN ISLANDS, BRITISH" ,
    "VIRGIN ISLANDS, U.S." ,
    "WALLIS AND FUTUNA ISLANDS" ,
    "WESTERN SAHARA" ,
    "YEMEN" ,
    "ZAMBIA" ,
    "ZIMBABWE" 
];
