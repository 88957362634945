<div class="main">
    <div class="list-container">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <div class="full" fxLayout="column" fxLayoutAlign="center center">
                <h2>My Kemppi</h2>
                <maintenance-message (disabledAppChange)="filterApplications($event)"></maintenance-message>
            </div>
        </div>

        <div *ngIf="applicationIds && applications && userServices" class="full" fxLayout="column" fxLayoutAlign="center center">

            <ng-container *ngFor="let appId of applicationIds">
                <!-- Applications (Not IAM )-->
                <ng-container *ngIf="applications[appId] && appId !== 'iam' && (userServices[appId] || applications[appId].publicAccess )">
                    <div class="list-row" fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px" [class.NOT-ACTIVE]="disabledApplicationIds.includes(appId)" (click)="navigateToService(appId)">
                        <div fxFlex="100px" fxFlex.xs="50px">
                            <img *ngIf="applications[appId].image" src="assets/images/services/{{applications[appId].image}}" class="application-image" [ngClass]="{square: applications[appId].imageStyle === 'square'}">
                        </div>
                        <div fxLayout="column" fxFlex>
                            <h2>{{ applications[appId].name }}</h2>
                            <div>{{ 'service_descriptions.' + appId | translate }}</div>
                            <div *ngIf="applications[appId].internalOnly" fxLayoutAlign="start center">
                                <mat-icon *ngIf="applications[appId].internalOnly" class="kemppi-symbols kemppi-icon">Ģ</mat-icon>
                                <i>{{ 'portal.only_for_kemppi_personnels' | translate}}</i>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

        </div>
    </div>
</div>