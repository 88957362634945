
        <h1>{{title | translate}}</h1>
        <div mat-dialog-content>
            <p translate>{{topic}}</p>
            <p><b>{{item}}</b></p>
            <p translate>{{text}}</p>
        </div>
        <div mat-dialog-actions fxLayoutAlign="end end">
          <button mat-raised-button mat-button color="primary" (click)="cancel()">{{ cancelText | translate }}</button>
          <button mat-raised-button mat-button color="accent"  (click)="ok()">{{ okText | translate }}</button>
        </div>
    