import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MatToolbar } from '@angular/material';
import { AuthService } from 'src/app/services/auth.service';
import { LocaleService } from 'src/app/services/locale.service';
import { AppConfigService } from 'src/app/services/app-config.service';

export interface IamModules {
    management?: boolean;
    portal?: boolean;
}

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ks-toolbar',
    templateUrl: './ks-toolbar.component.html',
    styleUrls: ['./ks-toolbar.component.scss']
})
export class KsToolbarComponent implements OnInit {

    authUserScope: string;
    allowedModules: IamModules;

    state = {
        saving: false,
        isMenuOpen: false,
        isUserMenuOpen: false,
        isLanguageMenuOpen: false,
        loading: false,
        deleting: false,
        isAdmin: false,
        error: ''
    };
    parentLink: string;
    subTitle: string;

    @Input() appInfo: any;
    // @Input() subTitle: string;
    @Input() file: string;
    @Input() hasMenu: boolean;
    @Input() hasBack: boolean;

    @Output() back = new EventEmitter();
    // @Output() changeLanguage = new EventEmitter();

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public authService: AuthService,
        private localeService: LocaleService,
        private appConfigService: AppConfigService
    ) {
    }

    ngOnInit() {
        this.state.loading = false;

        const environment = this.appConfigService.config.environment;
        if (environment && environment !== 'prod') {
            this.appInfo.title = this.appInfo.title + ` (${environment})`;
        }
        // TODO: Fix this, copy from below because not working properly after reload
        if (this.authService.userProfile) {
            this.allowedModules = this.authService.userProfile.permissions.modules as IamModules;
            this.authUserScope = this.authService.userProfile.permissions.scope;
        }

        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationEnd) {
                const routeData = this._getCurrentRouteData();
                if (routeData && routeData[0] && routeData[0].path) {
                    this.parentLink = routeData[0].path;
                    this.subTitle = routeData[0].path.replace('-', '_');
                } else {
                    this.subTitle = null;
                }
                // Set menu allowedModules by user.permissions.mudules
                if (this.authService.userProfile) {
                    this.allowedModules = this.authService.userProfile.permissions.modules as IamModules;
                    this.authUserScope = this.authService.userProfile.permissions.scope;
                }
            }
        });
    }

    _getCurrentRouteData() {
        let currentRoute = this.route.root;
        while (currentRoute.children[0] !== undefined) {
            currentRoute = currentRoute.children[0];
        }
        const routeData = currentRoute.snapshot.url;
        return routeData;
    }

    emitBack() {
        this.back.emit();
    }

    updateFile(file) {
        this.file = file;
    }

    login() {
        this.state.loading = true;
        this.state.isUserMenuOpen = false;
        this.authService.login();
    }

    logout() {
        this.state.loading = true;
        this.state.isUserMenuOpen = false;
        this.authService.logout();
    }

    setLanguage(language: string) {
        console.log('setLanguage', language);
        this.state.isLanguageMenuOpen = false;
        this.state.isUserMenuOpen = false;
        this.localeService.language = language;
    }
}
