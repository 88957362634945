import { Component, OnInit, Output, Input, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { MatProgressBar } from '@angular/material';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'ks-actionbar',
    templateUrl: './ks-actionbar.component.html',
    styleUrls: ['./ks-actionbar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KsActionbarComponent implements OnInit {
    @Input() hasCancel: boolean;
    @Input() hasSave: boolean;
    @Input() canSave: boolean;
    @Input() isSaving: boolean;
    @Output() save: any = new EventEmitter();
    @Output() cancel: any = new EventEmitter();
    constructor() { }

    ngOnInit() {
    }

    emitSave() {
        // Delegate upwards
        this.save.emit();
    }

    emitCancel() {
        // Delegate upwards
        this.cancel.emit();
    }
}
