import { Injectable } from '@angular/core';

export class ApplicationConfiguration {
    /**
     * Environment dev | stage | prod
     */
    environment: string;

    /**
     * Application frontend URL
     */
    frontendUrl: string;

    /**
     * IAM backend URL
     */
    iamBackendUrl: string;

    /**
     * Machinery backend URL
     */
    machineryBackendUrl: string;

    /** Auth0 domain used for oauth authentication */
    oauth2Domain: string;

    /** Auth0 clientId used for oauth authentication */
    oauth2ClientId: string;
}

@Injectable()
export class AppConfigService {
    constructor() { }

    get config(): ApplicationConfiguration {
        // this is set in main.ts which loads /config.json with AJAX
        return window['config'];
    }
}
