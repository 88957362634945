import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';

import {
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';

// Components
import { PortalPageComponent } from './components/portal-page/portal-page.component';
import { MyAccountPageComponent } from './components/my-account-page/my-account-page.component';
import { MaintenanceMessageComponent } from './components/maintenance-message/maintenance-message.component';

// Services


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule,
        MatProgressSpinnerModule, MatRadioModule, MatCheckboxModule, MatChipsModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule,
        FormsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsNavigationModule
    ],
    declarations: [
        PortalPageComponent,
        MyAccountPageComponent,
        MaintenanceMessageComponent
    ],
    providers: [
    ],
    entryComponents: [
    ],
    exports: [
    ]
})
export class PortalModule { }
