export interface IAMUser {

    /**
     * Unique id
     */
    id?: string;

    /**
     * Is active? Default = active (null or true)
     */
    active?: boolean;

    /**
     * Is deleted? Default = null (null or true)
     */
    deleted?: boolean;

    /**
     * Company code, same as used in Epicor / IAM
     * Owner & Visibility
     */
    company: string;

    /**
     * Partner id (Customer id), same as used in Epicor / IAM
     * Owner & Visibility
     */
    partnerId: string;

    /**
     *
     */
    data: {

        /**
         * Auth0 primary userId.
         * Is auth0|uuid for username-password users
         * and connectionName|email with ADFS users
         */
        userId?: string;

        /**
         * Name (full name)
         */
        name: string;

        /**
         * Given name
         */
        givenName?: string;

        /**
         * Family name
         */
        familyName?: string;

        /**
         * Partner name (Autofetched from Machinery)
         */
        partnerName?: string;

        /**
         * Email address
         */
        email: string;

        /**
         * Language
         */
        language?: string;

        /**
         * Country TEXT
         */
        country?: string;

        /**
         * Kemppi group code
         */
        groupCode?: string;

        /**
         * Kemppi group description
         */
        groupDesc?: string;

        /**
         * Roles
         * One role / application
         * Role is passed from IAM to application during login
         */
        roles: {
            [applicationId: string]: string;
        };

        /**
         * Application specific attributes
         * These attributes are passed from IAM to application during login
         */
        appAttributes?: IAMUserApplicationAttributes

        /**
         * Picture ?
         */
        picture?: string;

        /**
         * Email verified?
         */
        email_verified?: boolean;

        /**
         * Is the user a internal Kemppi user.
         * This user will use SSO authentication
         */
        internal?: boolean;

        /**
         * Is the user in initial state. Requires user self registration.
         * This allows us to create user upfront and allows user to make self registration after that.
         */
        initial?: boolean;

        /**
         * Was user created via self registration
         */
        selfRegistered?: boolean;

        /**
         * Is self registered user approved
         */
        approval?: REGISTRATION_APPROVAL;

        /**
         * General notes of user
         * Registration decline reason for example
         */
        notes?: string;
    };

    /**
     * Schema info
     */
    schema: SchemaInfo;

    /**
     * Version info
     */
    version?: VersionInfo;
}

export enum REGISTRATION_APPROVAL {
    approved = 'APPROVED',
    declined = 'DECLINED',
    undeterminated = 'UNDETERMINATED'
}

export interface Auth0User {
    user_id: string;
    email: string;
    name: string;
    picture: string;
    family_name?: string;
    given_name?: string;
    app_metadata: {
        language: string;
        company: string;
        partnerId: string;
        partnerName?: string;
        roles: IAMUser['data']['roles'];

        /** Did user self register via auth0 signup */
        selfRegistered?: boolean;
        /** State of user access approval (e.g. can they access the application or not) */
        approval?: IAMUser['data']['approval'];
        country?: string;
    };
    email_verified: boolean;
}

export interface SchemaInfo {
    type: string;
    version: string;
}

export interface VersionInfo {
    created?: {
        timestamp: string;
        user: {
            id: string;
            name: string;
            company?: string;
            partnerId?: string;
        };
    };
    modified?: {
        timestamp: string;
        user: {
            id: string;
            name: string;
            company?: string;
            partnerId?: string;
        };
    };
}

/**
 * See models/applications
 */
export interface IAMUserApplicationAttributes {
    'eordering'?: {
        kemppi?: boolean;
        trafimet?: boolean;
    },
    'ewarranty'?: {
        noPrices?: boolean;
    }
}

/**
 * Request body received from frontend when creating a user
 */
 export interface CreateUserRequestBody {
    email: string;
    name: string;
    roles: IAMUser['data']['roles'];
    country: string;
    language: string;
    internal?: boolean;
    initial?: boolean;
    partnerId: string;
    company: string;
    partnerName?: string;
    appAttributes: IAMUser['data']['appAttributes'];
}
