import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import {
    MatMenuModule,
    MatGridListModule,
    MatButtonModule,
    MatInputModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatButtonToggleModule,
    MatIconModule,
    MatDialogModule,
    MatSelectModule,
    MatRadioModule,
    MatChipsModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule
} from '@angular/material';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { KsBarcodeComponent } from './components/ks-barcode/ks-barcode.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        TranslateModule,
        ReactiveFormsModule,
        MatRadioModule,
        MatMenuModule,
        MatButtonModule,
        MatTooltipModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        MatIconModule,
        MatDialogModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatChipsModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatGridListModule
    ],
    declarations: [
        KsBarcodeComponent

    ],
    entryComponents: [
    ],
    exports: [
        KsBarcodeComponent,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    providers: [
    ]
})
export class KsFormSetModule {
}
