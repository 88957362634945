export const Auth0LogEventTypeCodes = {
    admin_update_launch: { event: 'Auth0 Update Launched', description: '' },
    api_limit: { event: 'Rate Limit on the Authentication API', description: 'The maximum number of requests to the Authentication API in given time has reached.' },
    cls: { event: 'Code/Link Sent', description: 'Passwordless login code/link has been sent' },
    coff: { event: 'Connector Offline', description: 'AD/LDAP Connector is offline' },
    con: { event: 'Connector Online', description: 'AD/LDAP Connector is online and working' },
    cs: { event: 'Code Sent', description: 'Passwordless login code has been sent' },
    depnote: { event: 'Deprecation Notice', description: '' },
    du: { event: 'Deleted User', description: 'User has been deleted.' },
    f: { event: 'Failed Login', description: '' },
    fc: { event: 'Failed by Connector', description: '' },
    fce: { event: 'Failed Change Email', description: 'Failed to change user email' },
    fco: { event: 'Failed by CORS', description: 'Origin is not in the Allowed Origins list for the specified application' },
    fcoa: { event: 'Failed cross-origin authentication', description: '' },
    fcp: { event: 'Failed Change Password', description: '' },
    fcph: { event: 'Failed Post Change Password Hook', description: '' },
    fcpn: { event: 'Failed Change Phone Number', description: '' },
    fcpr: { event: 'Failed Change Password Request', description: '' },
    fcpro: { event: 'Failed Connector Provisioning', description: 'Failed to provision a AD/LDAP connector' },
    fcu: { event: 'Failed Change Username', description: 'Failed to change username' },
    fd: { event: 'Failed Delegation', description: 'Failed to generate delegation token' },
    fdeac: { event: 'Failed Device Activation', description: 'Failed to activate device.' },
    fdeaz: { event: 'Failed Device Authorization Request', description: 'Device authorization request failed.' },
    fdecc: { event: 'User Canceled Device Confirmation', description: 'User did not confirm device.' },
    fdu: { event: 'Failed User Deletion', description: '' },
    feacft: { event: 'Failed Exchange', description: 'Failed to exchange authorization code for Access Token' },
    feccft: { event: 'Failed Exchange', description: 'Failed exchange of Access Token for a Client Credentials Grant' },
    fede: { event: 'Failed Exchange', description: 'Failed to exchange Device Code for Access Token' },
    fens: { event: 'Failed Exchange', description: 'Failed exchange for Native Social Login' },
    feoobft: { event: 'Failed Exchange', description: 'Failed exchange of Password and OOB Challenge for Access Token' },
    feotpft: { event: 'Failed Exchange', description: 'Failed exchange of Password and OTP Challenge for Access Token' },
    fepft: { event: 'Failed Exchange', description: 'Failed exchange of Password for Access Token' },
    fepotpft: { event: 'Failed Exchange', description: 'Failed exchange of Passwordless OTP for Access Token' },
    fercft: { event: 'Failed Exchange', description: 'Failed Exchange of Password and MFA Recovery code for Access Token' },
    fertft: { event: 'Failed Exchange', description: 'Failed Exchange of Refresh Token for Access Token' },
    ferrt: { event: 'Failed Exchange', description: 'Failed Exchange of Rotating Refresh Token' },
    flo: { event: 'Failed Logout', description: 'User logout failed' },
    fn: { event: 'Failed Sending Notification', description: 'Failed to send email notification' },
    fp: { event: 'Failed Login (Incorrect Password)', description: '' },
    fs: { event: 'Failed Signup', description: '' },
    fsa: { event: 'Failed Silent Auth', description: '' },
    fu: { event: 'Failed Login (Invalid Email/Username)', description: '' },
    fui: { event: 'Failed users import', description: 'Failed to import users' },
    fv: { event: 'Failed Verification Email', description: 'Failed to send verification email' },
    fvr: { event: 'Failed Verification Email Request', description: 'Failed to process verification email request' },
    gd_auth_failed: { event: 'MFA Auth failed', description: 'Multi-factor authentication failed. This could happen due to a wrong code entered for SMS/Voice/Email/TOTP factors, or a system failure.' },
    gd_auth_rejected: { event: 'MFA Auth rejected', description: 'A user rejected a Multi-factor authentication request via push-notification.' },
    gd_auth_succeed: { event: 'MFA Auth success', description: 'Multi-factor authentication success.' },
    gd_enrollment_complete: { event: 'MFA enrollment complete', description: 'A first time MFA user has successfully enrolled using one of the factors.' },
    gd_otp_rate_limit_exceed: { event: 'Too many failures', description: 'A user, during enrollment or authentication, enters an incorrect code more than the maximum allowed number of times. Ex: A user enrolling in SMS enters the 6-digit code wrong more than 10 times in a row.' },
    gd_recovery_failed: { event: 'Recovery failed', description: 'A user enters a wrong recovery code when attempting to authenticate.' },
    gd_recovery_rate_limit_exceed: { event: 'Too many failures', description: 'A user enters a wrong recovery code too many times.' },
    gd_recovery_succeed: { event: 'Recovery success', description: 'A user successfully authenticates with a recovery code.' },
    gd_send_pn: { event: 'Push notification sent', description: 'Push notification for MFA sent successfully sent.' },
    gd_send_sms: { event: 'SMS sent', description: 'SMS for MFA successfully sent.' },
    gd_send_sms_failure: { event: 'SMS sent failures', description: 'Attempt to send SMS for MFA failed.' },
    gd_send_voice: { event: 'Voice call made', description: 'Voice call for MFA successfully made.' },
    gd_send_voice_failure: { event: 'Voice call failure', description: 'Attempt to make Voice call for MFA failed.' },
    gd_start_auth: { event: 'Second factor started', description: 'Second factor authentication event started for MFA.' },
    gd_start_enroll: { event: 'Enroll started', description: 'Multi-factor authentication enroll has started.' },
    gd_tenant_update: { event: 'Guardian tenant update', description: '' },
    gd_unenroll: { event: 'Unenroll device account', description: 'Device used for second factor authentication has been unenrolled.' },
    gd_update_device_account: { event: 'Update device account', description: 'Device used for second factor authentication has been updated.' },
    limit_delegation: { event: 'Too Many Calls to /delegation', description: 'Rate limit exceeded to /delegation endpoint' },
    limit_mu: { event: 'Blocked IP Address', description: 'An IP address is blocked with 100 failed login attempts using different usernames, all with incorrect passwords in 24 hours, or 50 sign-up attempts per minute from the same IP address.' },
    limit_wc: { event: 'Blocked Account', description: 'An IP address is blocked with 10 failed login attempts into a single account from the same IP address.' },
    pwd_leak: { event: 'Breached password', description: 'Someone behind the IP address: ip attempted to login with a leaked password.' },
    s: { event: 'Success Login', description: 'Successful login event.' },
    sapi: { event: 'Success API Operation', description: '' },
    sce: { event: 'Success Change Email', description: '' },
    scoa: { event: 'Success cross-origin authentication', description: '' },
    scp: { event: 'Success Change Password', description: '' },
    scph: { event: 'Success Post Change Password Hook', description: '' },
    scpn: { event: 'Success Change Phone Number', description: '' },
    scpr: { event: 'Success Change Password Request', description: '' },
    scu: { event: 'Success Change Username', description: '' },
    sd: { event: 'Success Delegation', description: '' },
    sdu: { event: 'Success User Deletion', description: 'User successfully deleted' },
    seacft: { event: 'Success Exchange', description: 'Successful exchange of authorization code for Access Token' },
    seccft: { event: 'Success Exchange', description: 'Successful exchange of Access Token for a Client Credentials Grant' },
    sede: { event: 'Success Exchange', description: 'Successful exchange of device code for Access Token' },
    sens: { event: 'Success Exchange', description: 'Native Social Login' },
    seoobft: { event: 'Success Exchange', description: 'Successful exchange of Password and OOB Challenge for Access Token' },
    seotpft: { event: 'Success Exchange', description: 'Successful exchange of Password and OTP Challenge for Access Token' },
    sepft: { event: 'Success Exchange', description: 'Successful exchange of Password for Access Token' },
    sercft: { event: 'Success Exchange', description: 'Successful exchange of Password and MFA Recovery code for Access Token' },
    sertft: { event: 'Success Exchange', description: 'Successful exchange of Refresh Token for Access Token' },
    slo: { event: 'Success Logout', description: 'User successfully logged out' },
    ss: { event: 'Success Signup', description: '' },
    ssa: { event: 'Success Silent Auth', description: '' },
    sui: { event: 'Success users import', description: 'Successfully imported users' },
    sv: { event: 'Success Verification Email', description: '' },
    svr: { event: 'Success Verification Email Request', description: '' },
    sys_os_update_end: { event: 'Auth0 OS Update Ended', description: '' },
    sys_os_update_start: { event: 'Auth0 OS Update Started', description: '' },
    sys_update_end: { event: 'Auth0 Update Ended', description: '' },
    sys_update_start: { event: 'Auth0 Update Started', description: '' },
    ublkdu: { event: 'User login block released', description: 'User block setup by anomaly detection has been released' },
    w: { event: 'Warnings During Login' }
}