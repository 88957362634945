<div class="main">
    <!-- USER -->
    <div class="page-container" fxLayout="column" fxLayoutAlign="start start">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">Ž</mat-icon>
            <div fxLayout="column">
                <div fxLayout="row wrap" fxFlex>
                    <h2 fxFlex="200px" fxLayoutAlign="start center">{{ 'menu.account' | translate }}</h2>
                    <div fxFlex></div>
                </div>
            </div>
        </div>

        <div *ngIf="authUser" class="full container">

            <h2 class="mat-subhead">{{ 'common.user' | translate }}</h2>
            <div fxLayout="column">
                <div fxLayout="row">
                    <mat-form-field readonly="true">
                        <mat-label>{{ 'common.name' | translate }}</mat-label>
                        <input matInput [(ngModel)]="authUser.name" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <mat-form-field [style.width]="'300px'" readonly="true">
                        <mat-label>{{ 'common.email' | translate }}</mat-label>
                        <input matInput [(ngModel)]="authUser.email" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <mat-form-field [style.width]="'300px'" readonly="true">
                        <mat-label>{{ 'common.company' | translate }}</mat-label>
                        <input matInput [(ngModel)]="authUser.partnerName" readonly>
                    </mat-form-field>
                </div>
                <div fxLayout="row">
                    <mat-form-field>
                        <mat-label>{{ 'common.language' | translate }}</mat-label>
                        <mat-select [(value)]="authUser.language" required>
                            <mat-option *ngFor="let lang of availableLanguages" [value]="lang">{{ lang }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

        </div>
    </div>

    <div class="list-container full" fxLayout="column" fxLayoutAlign="start start">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">Ź</mat-icon>
            <div fxLayout="column">
                <h2>
                    {{ 'common.users' | translate }} -
                    <span *ngIf="authUser">{{ authUser.partnerName }}</span>
                </h2>
            </div>
        </div>
        <ng-container *ngIf="users">
            <div class="list-header-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="20px"></div>
                <div fxFlex="200px">
                    <small>{{ 'common.name' | translate }}</small>
                </div>
                <div fxFlex="200px">
                    <small>{{ 'common.email' | translate }}</small>
                </div>
                <div fxFlex></div>
                <div fxFlex="120px" fxHide.xs="true">
                    <small>{{ 'common.status' | translate }}</small>
                </div>
            </div>
            <div *ngFor="let user of users" fxLayout="column" fxLayoutAlign="start start" class="full">
                <div *ngIf="user" class="list-row" fxLayout="row">
                    <div fxFlex="20px"></div>
                    <div fxFlex="200px" fxLayoutAlign="start center">
                        <div>{{user.data.name}}</div>
                    </div>
                    <div fxFlex="200px" fxLayoutAlign="start center">
                        <div>{{user.data.email}}</div>
                    </div>
                    <div fxFlex></div>
                    <div fxFlex="120px" fxHide.xs="true" fxLayoutAlign="start center">
                        <small>{{user.data.approval}}</small>
                        <small *ngIf="user.data.initial">INITIAL</small>
                    </div>
                </div>
            </div>

        </ng-container>
        <div fxLayoutAlign="center center">
            <mat-progress-spinner matSuffix *ngIf="loadingUsers" [mode]="'indeterminate'" [color]="'primary'" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
        </div>
    </div>
</div>


<ks-actionbar [hasCancel]="true" [hasSave]="false" [canSave]="!state.loading" (save)="save()" (cancel)="cancel()">
    <div fxFlex></div>

    <div *ngIf="state.loading" fxLayout="row" fxLayoutAlign="start center">
        <mat-progress-spinner [mode]="'indeterminate'" [color]="'primary'" [diameter]="20" [strokeWidth]="2"></mat-progress-spinner>
        &nbsp;{{ 'common.loading' | translate }}
    </div>

    <div fxFlex></div>
</ks-actionbar>