<div class="main">
    <div class="list-container">
        <div class="full main-header" fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="20px">
            <mat-icon class="kemppi-symbols">ź</mat-icon>
            <div fxLayout="column">
                <h2>{{ 'menu.management' | translate }}</h2>
            </div>
        </div>

        <div class="full" fxLayout="column">
            <h2 class="mat-subhead">{{ 'common.search' | translate }}</h2>

            <div class="" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
                <mat-form-field fxFlex="180px">
                    <mat-label>{{ 'common.name' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchName" (change)="filterChanged()">
                </mat-form-field>
                <mat-form-field fxFlex="180px">
                    <mat-label>{{ 'common.email' | translate }}</mat-label>
                    <input matInput [(ngModel)]="searchEmail" (change)="filterChanged()">
                </mat-form-field>

                <div *ngIf="permissions.scope === 'global' || permissions.scope === 'company'" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <form *ngIf="permissions.scope === 'global' || permissions.scope === 'company'">
                        <mat-form-field fxFlex="180px">
                            <mat-label>{{ 'common.partner_name' | translate }}</mat-label>
                            <input type="text" matInput [formControl]="customersFormControl" [matAutocomplete]="auto" [(ngModel)]="searchPartner" (keyup)="searchCustomers()">
                            <mat-autocomplete #auto="matAutocomplete" (optionSelected)="changeCustomer($event.option)">
                                <mat-option *ngFor="let customer of customersList" [value]="customer">
                                    <small fxFlex>{{ customer.name }}</small>
                                    <small fxFlex="10px"></small>
                                    <small fxFlex="40px"><b>{{ customer.company }}</b></small>
                                    <small fxFlex="80px"><b>{{ customer.custId }}</b></small>
                                </mat-option>
                            </mat-autocomplete>
                        </mat-form-field>
                    </form>

                    <mat-form-field *ngIf="permissions.scope === 'global'" fxFlex="100px">
                        <mat-label>{{ 'common.company' | translate }}</mat-label>
                        <mat-select [(value)]="searchCompany" (selectionChange)="filterChanged()">
                            <mat-option value="">{{ 'common.any' | translate }}</mat-option>
                            <mat-option *ngFor="let company of companies" [value]="company.id">{{ company.id }} -
                                {{ company.name }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="permissions.scope === 'global' || permissions.scope === 'company'" fxFlex="100px">
                        <mat-label>{{ 'common.partner_id' | translate }}</mat-label>
                        <input matInput [(ngModel)]="searchPartnerId" (change)="filterChanged()">
                    </mat-form-field>
                </div>
                <div *ngIf="permissions.scope === 'global' || permissions.scope === 'company'" fxLayout="row wrap" fxLayoutAlign="start center" fxLayoutGap="20px">
                    <mat-form-field fxFlex="120px">
                        <mat-label>{{ 'common.applications' | translate }}</mat-label>
                        <mat-select [(value)]="searchApp" (selectionChange)="searchRole = null; filterChanged()">
                            <ng-container *ngFor="let appId of applicationIds">
                                <mat-option *ngIf="applications[appId] && applications[appId].roles" [value]="appId">{{ applications[appId].name }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="120px">
                        <mat-label>{{ 'common.role' | translate }}</mat-label>
                        <mat-select [(value)]="searchRole" (selectionChange)="filterChanged()">
                            <ng-container *ngIf="searchApp && applications[searchApp] && applications[searchApp].roles">
                                <mat-option *ngFor="let role of applications[searchApp].roles" [value]="role.roleId">{{ role.name }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field fxFlex="120px">
                        <mat-label>{{ 'maintain_users.registration_status' | translate }}</mat-label>
                        <mat-select [(value)]="searchApprovalStatus" (selectionChange)="filterChanged()">
                            <mat-option value="">{{ 'common.any' | translate }}</mat-option>
                            <mat-option [value]="regApprovalStates.undeterminated">{{ 'common.undetermined' | translate}}</mat-option>
                            <mat-option [value]="regApprovalStates.approved">{{ 'common.approved' | translate}}</mat-option>
                            <mat-option [value]="regApprovalStates.declined">{{ 'common.declined' | translate}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-checkbox [(ngModel)]="searchInternal" (change)="filterChanged()">{{ 'common.internal' | translate }} <small>(Kemppi SSO)</small></mat-checkbox>
                    <mat-checkbox [(ngModel)]="searchInitial" (change)="filterChanged()">{{ 'common.initial' | translate }} <small>(Migration)</small></mat-checkbox>
                </div>
                <div>
                    <button mat-button (click)="search()">{{ 'common.search' | translate }}</button>
                    <button mat-button color="warn" (click)="resetSearchParams(); search()">{{ 'common.reset' | translate }}</button>
                </div>
            </div>
        </div>

        <div>
            <button mat-raised-button color="accent" (click)="createNewUser()">
                <mat-icon>add</mat-icon>
                {{ 'maintain_users.add_new_user' | translate }}
            </button>
        </div>

        <div>
            <h2 class="mat-subhead">
                {{ 'common.users' | translate }}
            </h2>
        </div>
        <ng-container *ngIf="users">
            <div class="list-header-row" fxLayout="row" fxLayoutAlign="start center">
                <div fxFlex="80px" fxHide.xs="true" (click)="expandAll = !expandAll" fxLayout="row" fxLayoutAlign="start center">
                    <small>{{ 'common.expand' | translate }}</small>
                    <mat-icon *ngIf="expandAll">keyboard_arrow_down</mat-icon>
                    <mat-icon *ngIf="!expandAll">keyboard_arrow_right</mat-icon>
                </div>
                <div fxFlex="260px" (click)="sort('data.name.keyword')" fxLayoutAlign="start center">
                    <small>{{ 'common.name' | translate }}</small>
                    <mat-icon *ngIf="sortParams.key === 'data.name.keyword'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <ng-container *ngIf="permissions.scope === 'global' || permissions.scope === 'company'">
                    <div fxFlex="260px" (click)="sort('data.partnerName.keyword')" fxLayoutAlign="start center">
                        <small>{{ 'common.partner_name' | translate }}</small>
                        <mat-icon *ngIf="sortParams.key === 'data.partnerName.keyword'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                    </div>
                    <div fxFlex="80px" fxHide.xs="true" (click)="sort('company')" fxLayoutAlign="start center">
                        <small>{{ 'common.company' | translate }}</small>
                        <mat-icon *ngIf="sortParams.key === 'company'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                    </div>
                    <div fxFlex="80px" fxHide.xs="true" (click)="sort('partnerId')" fxLayoutAlign="start center">
                        <small>{{ 'common.partner_id' | translate }}</small>
                        <mat-icon *ngIf="sortParams.key === 'partnerId'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                    </div>
                    <div fxFlex="240px" fxHide.xs="true" fxLayoutAlign="start center">
                        <small>{{ 'common.roles' | translate }}</small>
                    </div>
                </ng-container>
                <ng-container *ngIf="permissions.scope === 'partner'">
                    <div fxFlex="200px" fxLayoutAlign="start center">
                        <small>{{ 'common.email' | translate }}</small>
                    </div>
                </ng-container>
                <div fxFlex></div>
                <div fxFlex="120px" fxHide.xs="true" (click)="sort('data.approval')" fxLayoutAlign="start center">
                    <small>{{ 'common.status' | translate }}</small>
                    <mat-icon *ngIf="sortParams.key === 'data.approval'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <div fxFlex="120px" (click)="sort('data.lastLogon')">
                    <small>{{ 'common.last_logon' | translate }}</small>
                    <mat-icon *ngIf="sortParams.key === 'data.lastLogon'" class="sort-arrow">{{ sortParams.direction === 'asc' ? 'arrow_downward' : 'arrow_upward'}}</mat-icon>
                </div>
                <!--
                <div fxFlex="240px">
                    <small>{{ 'common.id' | translate }}</small>
                </div>
                -->
            </div>
            <div *ngIf="users" fxLayout="column" fxLayoutAlign="start start">
                <ng-container *ngFor="let user of users; trackBy: trackById">
                    <div *ngIf="user" class="list-row" fxLayout="column" [ngClass]="{'NEW': user.data.approval === 'UNDETERMINATED', 'NOT-ACTIVE': !user.active || user.data.approval === 'DECLINED'}">
                        <div fxFlex fxLayout="row" fxLayoutAlign="start center">
                            <div fxFlex="80px" fxHide.xs="true" fxFlexAlign="stretch" fxLayoutAlign="end center" (click)="expandedItems[user.id] = !expandedItems[user.id]">
                                <div *ngIf="user.data.picture"><img [src]="user.data.picture" class="user-picture"></div>
                                <mat-icon *ngIf="expandAll || expandedItems[user.id]">keyboard_arrow_down</mat-icon>
                                <mat-icon *ngIf="!expandAll && !expandedItems[user.id]">keyboard_arrow_right</mat-icon>
                            </div>
                            <div fxFlex class="user-select-area" [routerLink]="['/management', user.id]" routerLinkActive="active">
                                <div fxFlex="260px" fxLayoutAlign="center start" fxLayout="column">
                                    <div>{{user.data.name}}</div>
                                    <div *ngIf="expandAll || expandedItems[user.id]">
                                        <small>{{user.data.email}}</small>
                                    </div>
                                </div>
                                <ng-container *ngIf="permissions.scope === 'global' || permissions.scope === 'company'">
                                    <div fxFlex="260px" fxLayoutAlign="start center">
                                        {{user.data.partnerName}}
                                    </div>
                                    <div fxFlex="80px" fxHide.xs="true" fxLayoutAlign="start center">
                                        {{user.company}}
                                    </div>
                                    <div fxFlex="80px" fxHide.xs="true" fxLayoutAlign="start center">
                                        {{user.partnerId}}
                                    </div>
                                    <div fxFlex="240px" fxHide.xs="true" fxLayoutAlign="start start" fxLayout="column">
                                        <ng-container *ngIf="user.data.roles">
                                            <ng-container *ngFor="let appId of objectKeys(user.data.roles); let i = index">
                                                <div *ngIf="applicationsFormatted[appId] && (expandAll || expandedItems[user.id] || i < 3)" class="user-application-roles">
                                                    <span fxFlex="100px">{{applicationsFormatted[appId].name}}:</span>{{applicationsFormatted[appId].roles[user?.data?.roles[appId]]}}
                                                </div>
                                                <div *ngIf="!expandAll && !expandedItems[user.id] && i === 3" class="user-application-roles">...</div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="permissions.scope === 'partner'">
                                    <div fxFlex="200px" fxLayoutAlign="start center">
                                        {{user.data.email}}
                                    </div>
                                </ng-container>
                                <div fxFlex></div>
                                <div fxFlex="120px" fxHide.xs="true" fxLayoutAlign="center start" fxLayout="column" class="user-status">
                                    <small *ngIf="user.data.approval === 'UNDETERMINATED'">{{ 'common.undetermined' | translate}}</small>
                                    <small *ngIf="user.data.approval === 'APPROVED'">{{ 'common.approved' | translate}}</small>
                                    <small *ngIf="user.data.approval === 'DECLINED'">{{ 'common.declined' | translate}}</small>
                                    <small *ngIf="user.data.initial">{{ 'common.initial' | translate}}</small>
                                    <small *ngIf="!user.active">{{ 'common.inactive' | translate}}</small>
                                </div>
                                <div fxFlex="120px" fxHide.xs="true" fxLayoutAlign="start center" class="user-last-logon">
                                    {{user.data.lastLogon | date:'yyyy-MM-dd HH:mm'}}
                                </div>
                                <!--
                                <div fxFlex="240px" fxLayoutAlign="start center">
                                    <small>{{user.id}}</small>
                                </div>
                                -->
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </ng-container>
        <div fxLayoutAlign="center center">
            <mat-progress-spinner matSuffix *ngIf="loadingUsers" [mode]="'indeterminate'" [color]="'primary'" [diameter]="48" [strokeWidth]="4"></mat-progress-spinner>
        </div>
        <div fxLayout="row" class="width100">
            <div fxFlex>
                <small><i>{{ 'common.results' | translate }}: {{resultHits}} / {{totalHits}}</i></small>
            </div>
            <div fxFlex fxLayoutAlign="center center">
                <button *ngIf="!loadingUsers && users && users.length !== this.totalHits" mat-button color="primary" (click)="loadMore()">
                    <mat-icon class="kemppi-symbols">ş</mat-icon> {{ 'common.load_more' | translate }}
                </button>
            </div>
            <div fxFlex></div>
        </div>

    </div>

    <ks-actionbar [hasCancel]="false" [hasSave]="false">
        <div fxLayout="row" fxFlex>

        </div>
    </ks-actionbar>

</div>