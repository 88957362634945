import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {
    MatButtonModule, MatInputModule, MatFormFieldModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule,
    MatMenuModule, MatCardModule, MatTooltipModule, MatSelectModule, MatCheckboxModule, MatButtonToggleModule, MatDialogModule,
    MatSnackBarModule, MatDatepickerModule, MatAutocompleteModule,
    MAT_LABEL_GLOBAL_OPTIONS,
    MAT_DATE_LOCALE,
    DateAdapter
} from '@angular/material';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

// Angular
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';

// Modules
import { BaseModule } from './modules/base/base.module';
import { KsFormSetModule } from './shared/ks-formset/ks-formset.module';
import { KsNavigationModule } from './shared/ks-navigation/ks-navigation.module';
import { ManagementModule } from './modules/management/management.module';
import { PortalModule } from './modules/portal/portal.module';
import { WarCustomModule } from './shared/war-custom/war-custom.module';
import { DialogsModule } from './shared/dialogs/dialogs.module';

// Services
import { AppConfigService } from './services/app-config.service';
import { AuthService } from './services/auth.service';
import { CanDeactivateGuard } from './services/can-deactivate-guard.service';
import { LocaleService } from 'src/app/services/locale.service';
import { LoginService } from './services/login.service';
import { MachineryService } from './services/machinery.service';
import { StateService } from './services/state.service';

// Translate
import { registerLocaleData } from '@angular/common';
import localeEnGB from '@angular/common/locales/en-GB';
import localeFi from '@angular/common/locales/fi';
import { MaintenanceService } from './services/maintenance.service';

registerLocaleData(localeEnGB, 'en-GB');
registerLocaleData(localeFi, 'fi');

export function createTranslateLoader(http: HttpClient) {
    const date = new Date().getTime(); // Use time as suffix to force reload translation file
    return new TranslateHttpLoader(http, '../../assets/i18n/', '.json?' + date);
}


@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        MatButtonModule, MatInputModule, MatFormFieldModule, MatIconModule, MatProgressSpinnerModule, MatProgressBarModule,
        MatMenuModule, MatCardModule, MatTooltipModule, MatSelectModule, MatCheckboxModule, MatButtonToggleModule, MatDialogModule,
        MatSnackBarModule, MatDatepickerModule, MatAutocompleteModule,
        MatMomentDateModule,
        FormsModule, ReactiveFormsModule,

        BaseModule,
        KsFormSetModule,
        KsNavigationModule,
        WarCustomModule,
        DialogsModule,

        ManagementModule,
        PortalModule,

        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
                // deps: [Http]
            }
        }),
    ],
    providers: [
        AppConfigService,
        AuthService,
        LocaleService,
        LoginService,
        MachineryService,
        StateService,
        MaintenanceService,

        // Global input mat-form-filed mat-label option to float always without fancy bouncing
        { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'always'} },

        CanDeactivateGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
