import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import {
    MatButtonModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    MatIconModule,
    MatListModule,
    MatToolbarModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatCheckboxModule,
    MatChipsModule,
    MatSnackBarModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatDialogModule
} from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

import { KsNavigationModule } from '../../shared/ks-navigation/ks-navigation.module';

// Components
import { UserEditPageComponent } from './components/user-edit-page/user-edit-page.component';
import { UsersListingPageComponent } from './components/users-listing-page/users-listing-page.component';

// Services
import { ManagementService } from 'src/app/services/management.service';


@NgModule({
    imports: [
        CommonModule,
        MatButtonModule, MatInputModule, MatSelectModule, MatFormFieldModule, MatIconModule, MatListModule, MatToolbarModule, MatProgressBarModule,
        MatProgressSpinnerModule, MatRadioModule, MatCheckboxModule, MatChipsModule, MatSnackBarModule, MatTooltipModule, MatAutocompleteModule,
        MatTabsModule, MatDialogModule,
        FormsModule, ReactiveFormsModule,
        FlexLayoutModule,
        HttpClientModule,
        TranslateModule, // See app.module for loader
        RouterModule,

        KsNavigationModule
    ],
    declarations: [
        UserEditPageComponent,
        UsersListingPageComponent
    ],
    providers: [
        ManagementService
    ],
    entryComponents: [
    ],
    exports: [
    ]
})
export class ManagementModule { }
