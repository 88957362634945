import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { MaintenanceService, MaintenanceMessage } from '../../../../services/maintenance.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { IApplicationIds } from '../../../../models/applications';


/**
 * Component showing maintenance messages to the end user. If no message exists the component is empty.
 * Maintenance info is retrieved from "maintenance-message.json" at app root.
 */
@Component({
    selector: 'maintenance-message',
    templateUrl: './maintenance-message.component.html',
    styleUrls: ['./maintenance-message.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class MaintenanceMessageComponent implements OnInit, OnDestroy {
    onDestroy$ = new Subject<void>();

    @Output() disabledAppChange =  new EventEmitter<IApplicationIds[]>();

    /** Contains a message explaining the current problems with services */
    maintenanceMessage: MaintenanceMessage = {
        message: '',
        disabledApps: [],
    };


    constructor(
        private maintenanceService: MaintenanceService, 
        private cdRef: ChangeDetectorRef
    ) { }
    

    ngOnInit() {
        /** Requests for maintenance message */
        this.maintenanceService.getMaintenanceMessage().pipe(
            takeUntil(this.onDestroy$)
        ).subscribe(message => {
            // Merge the "new message" with old state of the message
            this.maintenanceMessage = Object.assign(this.maintenanceMessage, message);
            this.disabledAppChange.next(message.disabledApps || []);

            this.cdRef.markForCheck();
        });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
        this.onDestroy$.complete();
    }
}
